<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <div>
              <router-link
                :to="'/landing-page/create'"
                class="btn btn-success mb-3"
              >
                <i class="mdi mdi-plus me-1"></i> Add New Page
              </router-link>

              <button
                type="button"
                class="btn btn-warning mb-3"
                style="margin-left: 5px"
                @click="ListLp()"
                :disabled="statusRefresh == 0 ? true : false"
              >
                <i class="fas fa-undo-alt"></i>
                Refresh
              </button>
            </div>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_filter" class="dataTables_filter text-md-end">
            <label class="d-inline-flex align-items-center fw-normal">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Search..."
                class="form-control form-control-sm ms-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <div class="col-12">
        <p>Active Landing: <b>{{ countLP }}</b></p>
      </div>
      <div class="col-12">
        <div
          class="
            table table-centered
            datatable
            dt-responsive
            nowrap
            table-card-list
            dataTable
            no-footer
            dtr-inline
          "
        >
          <!-- Table -->

          <b-table
            table-class="table table-centered datatable table-card-list"
            thead-tr-class="bg-transparent"
            :items="orderData"
            :fields="fields"
            responsive="sm"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:cell(id)="data">
              <a href="javascript: void(0);" class="text-dark fw-bold">{{
                data.item.id
              }}</a>
            </template>

            <template v-slot:cell(name)="data">
              <a href="#" class="text-body">{{ data.item.name }}</a>
            </template>

            <template v-slot:cell(status)="data">
              <div class="form-check form-switch form-switch-lg mb-3" dir="ltr">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="customSwitchsizelg"
                  :disabled="statusValue == 0 ? true : false"
                  @click="statusLp(data.item.id)"
                  :checked="data.item.status == 1 ? true : false"
                />
              </div>
            </template>

            <template v-slot:cell(action)="data">
              <ul class="list-inline mb-0">
                <li class="list-inline-item">
                  <router-link
                    :to="'/landing-page/update/' + data.item.id"
                    class="px-2 text-primary"
                    v-b-tooltip.hover
                    title="Edit"
                  >
                    <i class="uil uil-pen font-size-20"></i>
                  </router-link>
                </li>
                <li class="list-inline-item">
                  <a
                    href="javascript:void(0);"
                    class="px-2 text-danger"
                    v-b-tooltip.hover
                    title="Delete"
                    @click="deleteLpPopup(data.item.id)"
                  >
                    <i class="uil uil-trash-alt font-size-20"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a
                    href="javascript:void(0);"
                    class="px-2 text-warning"
                    v-b-tooltip.hover
                    title="Clone"
                    @click="clonePopup(data.item.id)"
                  >
                    <i class="uil uil-copy-alt font-size-20"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a
                    :href="
                      'https://lp.estetikinternational.com/lp/' +
                        data.item.lang +
                        '/' +
                        data.item.slug
                    "
                    target="_blank"
                    class="px-2 text-info"
                  >
                    <i class="uil uil-eye font-size-20"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <router-link
                    :to="'/landing-page/crm/' + data.item.id"
                    class="px-2 text-primary"
                    v-b-tooltip.hover
                    title="CRM "
                  >
                    <i class="uil uil-cog font-size-20"></i>
                  </router-link>
                </li>
              </ul>
            </template>
          </b-table>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-end">
              <ul class="pagination pagination-rounded">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { API } from "@/state/API2";

import { POPUP } from "@/state/popup";
import Swal from "sweetalert2";
// import axios from "axios";
export default {
  page: {
    title: "Landing Page",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Landing Page",
      items: [
        {
          text: "Landing Page",
        },
        {
          text: "Orders",
          active: true,
        },
      ],
      orderData: [
        {
          name: "Dönüş Yok",
        },
      ],
      statusValue: 1,
      statusRefresh: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      countLP: 0,
      fields: [
        {
          key: "check",
          label: "",
        },
        {
          key: "id",
          label: "ID ↑↓",
          sortable: true,
        },
        {
          key: "date",
          label: "Date ↑↓",
          sortable: true,
        },
        {
          key: "name",
          label: "Name ↑↓",
          sortable: true,
        },
        {
          key: "status",
          label: "Status ↑↓",
          sortable: true,
        },
        {
          key: "action",
          label: "Detail",
          sortable: true,
        },
      ],
    };
  },
  middleware: "authentication",
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.orderData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.ListLp();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async ListLp() {
      this.statusRefresh = 0;
      await API.Get(API.LandingPage.Index + "?type=tc").then((response) => {
        console.log(response.data.status);
        this.orderData = response.data.order;
        this.countLP = response.data.count;
        var data;
        if (response.data.status === "success") {
          data = "Your work has been refresh";
          POPUP.popupClick(data);

          this.statusRefresh = 1;
        } else {
          data = "Your work could not be renewed";
          POPUP.popupClickNot(data);
          this.statusRefresh = 1;
        }
      });
    },
    async statusLp(id) {
      this.statusValue = 0;
      await API.Get(
        API.LandingPage.Status + id + "?token=" + localStorage.getItem("token")
      ).then((response) => {
        console.log(response.data.status);
        if (response.data.status == "success") {
          this.statusValue = 1;
        }
        var data = "Your work has been update";
        POPUP.popupClick(data);
      });
    },
    deleteLpPopup(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.deleteLp(id);
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
          this.ListLp();
        }
      });
    },
    async deleteLp(id) {
      await API.Get(
        API.LandingPage.Delete + id + "?token=" + localStorage.getItem("token")
      ).then((response) => {
        console.log(response.data.status);
      });
    },
    
    clonePopup(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, clone it!",
      }).then((result) => {
        if (result.value) {
          this.cloneLp(id);
          Swal.fire("Clone!", "Your file has been clone.", "success");
          this.ListLp();
        }
      });
    },
    async cloneLp(id) {
      await API.Get(
        API.LandingPage.Clone + id + "?token=" + localStorage.getItem("token")
      ).then((response) => {
        console.log(response.data.status);
      });
    },
  },
};
</script>
