import { getFirebaseBackend } from "../../helpers/firebase/authUtils";
import { API } from "@/state/API";

export const state = {
  currentUser: sessionStorage.getItem("authUser"),
  token: localStorage.getItem("token"),
};

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue;
  },
  SET_TOKEN(state, newValue) {
    state.token = newValue;
  },
};

export const getters = {
  // Whether the user is currently logged in.
  loggedIn() {
    return localStorage.getItem("token");
  },
  token() {
    return state.token;
  },
};

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {
    dispatch("validate");
  },
  // Logs in the current user.
  async logIn(commit, { email, password } = {}) {
    const response = await API.Post(API.Auth.Login, { email, password });
    if (response.data.status) {
      saveState("token", response.data.data.token);
      return true;
    }
    if (!response.data.is_active) {
      return "onaylanmadi";
    }

    return false;
  },

  async checkToken({ getters }) {
    const token = getters.loggedIn;

    const response = await API.Post(API.Auth.CheckToken, { token });

    return response.data.status;
  },

  // Logs out the current user.
  logOut({ commit }) {
    // eslint-disable-next-line no-unused-vars
    commit("SET_CURRENT_USER", null);
    localStorage.removeItem("token");
    return true;
  },

  // register the user
  register({ commit, dispatch, getters }, { email, password } = {}) {
    if (getters.loggedIn) return dispatch("validate");

    return getFirebaseBackend()
      .registerUser(email, password)
      .then((response) => {
        const user = response;
        commit("SET_CURRENT_USER", user);
        return user;
      });
  },

  // register the user
  // eslint-disable-next-line no-unused-vars
  resetPassword({ commit, dispatch, getters }, { email } = {}) {
    if (getters.loggedIn) return dispatch("validate");

    return getFirebaseBackend()
      .forgetPassword(email)
      .then((response) => {
        const message = response.data;
        return message;
      });
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  // eslint-disable-next-line no-unused-vars
  validate({ commit, state }) {
    if (!state.currentUser) return Promise.resolve(null);
    const user = getFirebaseBackend().getAuthenticatedUser();
    commit("SET_CURRENT_USER", user);
    return user;
  },
};

// ===
// Private helpers
// ===

function saveState(key, state) {
  window.localStorage.setItem(key, state);
}
