import store from "@/state/store";
// import giris from "@/state/giris";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/pages/account/login"),
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (await store.dispatch("auth/checkToken")) {
          // Redirect to the home page instead
          next({
            name: "home",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/pages/auth/register-1"),
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (await store.dispatch("auth/checkToken")) {
          // Redirect to the home page instead
          next({
            name: "home",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot-password",
    component: () => import("../views/pages/account/forgot-password"),
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (await store.dispatch("auth/checkToken")) {
          // Redirect to the home page instead
          next({
            name: "home",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch("auth/logOut");

        next("/login");
      },
    },
  },
  {
    path: "/loginNew",
    name: "loginNew",
    component: () => import("../views/pages/auth/login-1"),
  },
  {
    path: "/",
    name: "home",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/dashboard/index"),
  },

  /* ********************************** */
  {
    path: "/landing-page",
    name: "Landing Page",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/landing/index"),
  },
  {
    path: "/landing-page/create",
    name: "Landing Page Create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/landing/create"),
  },

  {
    path: "/landing-page/update/:id",
    name: "Landing Page Update",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/landing/update"),
  },
  {
    path: "/landing-page/crm/:id",
    name: "Landing Page CRM",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/landing/crm"),
  },

  {
    path: "/user",
    name: "Users",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/users/index"),
  },
  {
    path: "/utm-ad",
    name: "Utm Ad Cases",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/utmAd/index"),
  },
  {
    path: "/utm-ad/update/:id",
    name: "Utm Ad Update",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/utmAd/update"),
  },
  {
    path: "/utm-ad/create",
    name: "Utm Ad Create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/utmAd/create"),
  },

  {
    path: "/profile",
    name: "Profile",
    meta: {
      authRequired: true,
    },
    component: () => {
      if (localStorage.getItem("company") == 1) {
        return import("../views/pages/company/profile/index");
      } else {
        return import("../views/pages/contacts/profile");
      }
    },
  },

  {
    path: "/lead",
    name: "Leads",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/lead/index"),
  },
  {
    path: "/lead/detail/:id",
    name: "Leads Detail",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/lead/detail"),
  },
  {
    path: "/crm-campaign-table",
    name: "CRM Campaign Table",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/crmlead/data/campaign"),
  },
  {
    path: "/crm-lead-source-table",
    name: "CRM Lead Source Table",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/crmlead/data/leadSource"),
  },
  {
    path: "/crm-lead-medium-table",
    name: "CRM Lead Medium Table",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/crmlead/data/medium"),
  },

  {
    path: "/crm-lead",
    name: "CRM Leads",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/crmlead/index"),
  },
  {
    path: "/crm-lead/detail/:id",
    name: "CRM Leads Detail",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/crmlead/detail"),
  },

  {
    path: "/home-page",
    name: "HomePage",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/homePage/index"),
  },
  {
    path: "/home-page/update/:id",
    name: "Home Page Update",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/homePage/update"),
  },
  {
    path: "/home-page/create",
    name: "Home Page Create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/homePage/create"),
  },

  {
    path: "/language",
    name: "Language",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/language/index"),
  },

  {
    path: "/language/create",
    name: "Language Create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/language/create"),
  },
  {
    path: "/language/update/:id",
    name: "Language Update",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/language/update"),
  },

  {
    path: "/category",
    name: "Category",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/category/index"),
  },

  {
    path: "/category/create",
    name: "Category Create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/category/create"),
  },

  {
    path: "/category/update/:id",
    name: "Category Update ",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/category/update"),
  },

  {
    path: "/category/before-after/:id",
    name: "Category Before After ",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/category/photo/index"),
  },
  {
    path: "/category/lang/:id",
    name: "Category Language ",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/category/lang/index"),
  },
  {
    path: "/category/lang/:id/create",
    name: "Category Language Create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/category/lang/create"),
  },
  {
    path: "/category/lang/:id/update/:langId",
    name: "Category Language Update",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/category/lang/update"),
  },

  {
    path: "/category/faq/:id",
    name: "Category FAQ ",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/category/faq/index"),
  },
  {
    path: "/category/faq/:id/create",
    name: "Category FAQ Create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/category/faq/create"),
  },
  {
    path: "/category/faq/:id/update/:langId",
    name: "Category FAQ Update",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/category/faq/update"),
  },

  {
    path: "/category/information/:id",
    name: "Category Information ",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/category/information/index"),
  },
  {
    path: "/category/information/:id/create",
    name: "Category Information Create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/category/information/create"),
  },
  {
    path: "/category/information/:id/update/:infoId",
    name: "Category Information Update",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/category/information/update"),
  },

  {
    path: "/doctors-page",
    name: "Doctors Page",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/doctorsPage/index"),
  },
  {
    path: "/doctors-page/update/:id",
    name: "Doctors Page Update",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/doctorsPage/update"),
  },
  {
    path: "/doctors-page/create",
    name: "Doctors Page Create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/doctorsPage/create"),
  },
  {
    path: "/doctors",
    name: "Doctors",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/doctors/index"),
  },

  {
    path: "/doctors/create",
    name: "Doctors Create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/doctors/create"),
  },

  {
    path: "/doctors/update/:id",
    name: "Doctors Update ",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/doctors/update"),
  },

  {
    path: "/doctors/photo/:id/",
    name: "Doctors Photo",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/doctors/photo/index"),
  },
  {
    path: "/doctors/photo/:id/create",
    name: "Doctors Photo Create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/doctors/photo/create"),
  },

  {
    path: "/doctors/lang/:id",
    name: "Doctors Language ",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/doctors/lang/index"),
  },
  {
    path: "/doctors/lang/:id/create",
    name: "Doctors Language Create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/doctors/lang/create"),
  },
  {
    path: "/doctors/lang/:id/update/:langId",
    name: "Doctors Language Update",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/doctors/lang/update"),
  },

  {
    path: "/doctors/techniq/:id",
    name: "Doctors Technig ",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/doctors/techniq/index"),
  },
  {
    path: "/doctors/techniq/:id/create",
    name: "Doctors Technig Create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/doctors/techniq/create"),
  },
  {
    path: "/doctors/techniq/:id/update/:techniqId",
    name: "Doctors Technig Update",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/doctors/techniq/update"),
  },

  {
    path: "/doctors/society/:id",
    name: "Doctors Society ",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/doctors/society/index"),
  },
  {
    path: "/doctors/society/:id/create",
    name: "Doctors Society Create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/doctors/society/create"),
  },
  {
    path: "/doctors/society/:id/update/:techniqId",
    name: "Doctors Society Update",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/doctors/society/update"),
  },
  {
    path: "/doctors/cerfiticate/:id",
    name: "Doctors Cerfiticate",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/doctors/cerfiticate"),
  },

  {
    path: "/doctors/category/:id",
    name: "Doctors Category",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/doctors/category/index"),
  },

  {
    path: "/highlights",
    name: "Highlights",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/highlights/index"),
  },

  {
    path: "/highlights/create/:type",
    name: "Highlights Create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/highlights/create"),
  },
  {
    path: "/highlights/update/:id",
    name: "Highlights Update",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/highlights/update"),
  },

  {
    path: "/highlights/lang/:id",
    name: "Highlights Language",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/highlights/lang/index"),
  },
  {
    path: "/highlights/lang/:id/create",
    name: "Highlights Language Create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/highlights/lang/create"),
  },
  {
    path: "/highlights/lang/:id/update/:langId",
    name: "Highlights Language Update",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/highlights/lang/update"),
  },

  {
    path: "/patient",
    name: "Pateint",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/patient/index"),
  },

  {
    path: "/patient/create",
    name: "Patient Create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/patient/create"),
  },
  {
    path: "/patient/update/:id",
    name: "Patient Update",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/patient/update"),
  },
  {
    path: "/patient/lang/:id",
    name: "Patient Language",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/patient/lang/index"),
  },
  {
    path: "/patient/lang/:id/create",
    name: "Patient Language Create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/patient/lang/create"),
  },
  {
    path: "/patient/lang/:id/update/:langId",
    name: "Patient Language Update",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/patient/lang/update"),
  },

  {
    path: "/patient-page",
    name: "Patient Page",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/patientPage/index"),
  },
  {
    path: "/patient-page/update/:id",
    name: "Patient Page Update",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/patientPage/update"),
  },
  {
    path: "/patient-page/create",
    name: "Patient Page Create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/patientPage/create"),
  },
  {
    path: "/top-on",
    name: "Top On",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/topon/index"),
  },
  {
    path: "/top-one/update/:id",
    name: "Top On Update",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/topon/update"),
  },
  {
    path: "/top-on/create",
    name: "Top On Create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/topon/create"),
  },

  {
    path: "/comment",
    name: "Comment",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/comment/index"),
  },
  {
    path: "/comment/update/:id",
    name: "Comment Update",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/comment/update"),
  },
  {
    path: "/comment/create",
    name: "Comment Create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/comment/create"),
  },

  {
    path: "/about-page",
    name: "About Page",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/aboutPage/index"),
  },
  {
    path: "/about-page/update/:id",
    name: "About Page Update",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/aboutPage/update"),
  },
  {
    path: "/about-page/create",
    name: "About Page Create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/aboutPage/create"),
  },


  {
    path: "/whatsapp-code",
    name: "Whatsapp Code",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/wpCode/index"),
  },
  {
    path: "/whatsapp-code/update/:id",
    name: "Whatsapp Code Update",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/wpCode/update"),
  },
  {
    path: "/whatsapp-code/create",
    name: "Whatsapp Code Create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/wpCode/create"),
  },


  {
    path: "/campaign-type",
    name: "Campaign Type",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/campaignType/index"),
  }, 
  {
    path: "/campaign-type/create",
    name: "Campaign Type Create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/campaignType/create"),
  }, 


  {
    path: "/campaign-type/:id/month",
    name: "Campaign Type Month",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/campaignType/month/index"),
  }, 
  {
    path: "/campaign-type/:id/month/create",
    name: "Campaign Type Month Create",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/campaignType/month/create"),
  },
  {
    path: "/campaign-type/:id/month/update/:name",
    name: "Campaign Type Month Update",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/campaignType/month/update"),
  },

  // /* ********************************** */
  // {
  //   path: "/chat",
  //   name: "chat",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/chat/index"),
  // },
  // {
  //   path: "/ecommerce/products",
  //   name: "Products",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ecommerce/products"),
  // },
  // {
  //   path: "/ecommerce/product-detail/:id",
  //   name: "Product Detail",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ecommerce/product-detail"),
  // },
  // {
  //   path: "/ecommerce/orders",
  //   name: "Orders",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ecommerce/orders"),
  // },
  // {
  //   path: "/ecommerce/customers",
  //   name: "Customers",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ecommerce/customers"),
  // },
  // {
  //   path: "/ecommerce/cart",
  //   name: "Cart",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ecommerce/cart"),
  // },
  // {
  //   path: "/ecommerce/checkout",
  //   name: "Checkout",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ecommerce/checkout"),
  // },
  // {
  //   path: "/ecommerce/shops",
  //   name: "Shops",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ecommerce/shops"),
  // },
  // {
  //   path: "/ecommerce/add-product",
  //   name: "Add Product",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ecommerce/add-product"),
  // },
  // {
  //   path: "/email/inbox",
  //   name: "Inbox",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/email/inbox"),
  // },
  // {
  //   path: "/email/reademail/:id",
  //   name: "Read Email",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/email/reademail"),
  // },
  // {
  //   path: "/invoices/detail",
  //   name: "Invoice Detail",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/invoices/detail"),
  // },
  // {
  //   path: "/invoices/list",
  //   name: "Invoice List",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/invoices/list"),
  // },
  // {
  //   path: "/contacts/grid",
  //   name: "User Grid",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/contacts/grid"),
  // },
  // {
  //   path: "/contacts/list",
  //   name: "User List",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/contacts/list"),
  // },

  // {
  //   path: "/utility/starter",
  //   name: "Starter-page",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/utility/starter"),
  // },
  // {
  //   path: "/utility/maintenance",
  //   name: "maintenance",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/utility/maintenance"),
  // },
  // {
  //   path: "/utility/comingsoon",
  //   name: "comingsoon",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/utility/comingsoon"),
  // },
  // {
  //   path: "/utility/timeline",
  //   name: "timeline",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/utility/timeline"),
  // },
  // {
  //   path: "/utility/faqs",
  //   name: "faqs",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/utility/faqs"),
  // },
  // {
  //   path: "/utility/pricing",
  //   name: "pricing",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/utility/pricing"),
  // },
  // {
  //   path: "/utility/404",
  //   name: "error-404",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/utility/404"),
  // },
  // {
  //   path: "/utility/500",
  //   name: "error-500",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/utility/500"),
  // },
  // {
  //   path: "/form/elements",
  //   name: "elements",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/form/elements"),
  // },
  // {
  //   path: "/form/validation",
  //   name: "validation",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/form/validation"),
  // },
  {
    path: "/form/advanced",
    name: "form-advanced",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/form/advanced"),
  },
  // {
  //   path: "/form/editor",
  //   name: "editor",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/form/editor"),
  // },
  // {
  //   path: "/form/upload",
  //   name: "upload",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/form/upload"),
  // },
  // {
  //   path: "/form/repeater",
  //   name: "repeater",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/form/repeater"),
  // },
  // {
  //   path: "/form/wizard",
  //   name: "wizard",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/form/wizard"),
  // },
  // {
  //   path: "/form/mask",
  //   name: "mask",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/form/mask"),
  // },
  // {
  //   path: "/tables/basic",
  //   name: "basic-table",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/tables/basic"),
  // },
  // {
  //   path: "/tables/advanced",
  //   name: "advanced",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/tables/advanced"),
  // },
  // {
  //   path: "/charts/apex",
  //   name: "apex",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/charts/apex/index"),
  // },
  // {
  //   path: "/charts/chartist",
  //   name: "chartist",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/charts/chartist/index"),
  // },
  // {
  //   path: "/charts/chartjs",
  //   name: "chartjs",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/charts/chartjs/index"),
  // },
  // {
  //   path: "/charts/echart",
  //   name: "echart",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/charts/echart/index"),
  // },
  // {
  //   path: "/icons/unicons",
  //   name: "unicons",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/icons/unicons"),
  // },
  // {
  //   path: "/icons/boxicons",
  //   name: "boxicons",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/icons/boxicons"),
  // },
  // {
  //   path: "/icons/materialdesign",
  //   name: "materialdesign",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/icons/materialdesign"),
  // },
  // {
  //   path: "/icons/dripicons",
  //   name: "dripicons",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/icons/dripicons"),
  // },
  // {
  //   path: "/icons/fontawesome",
  //   name: "fontawesome",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/icons/fontawesome"),
  // },
  // {
  //   path: "/maps/google",
  //   name: "google",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/maps/google"),
  // },
  // {
  //   path: "/maps/leaflet",
  //   name: "leaflet",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/maps/leaflet"),
  // },
  // {
  //   path: "/ui/alerts",
  //   name: "alerts",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ui/alerts"),
  // },
  // {
  //   path: "/ui/buttons",
  //   name: "buttons",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ui/buttons"),
  // },
  // {
  //   path: "/ui/cards",
  //   name: "cards",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ui/cards"),
  // },
  // {
  //   path: "/ui/carousel",
  //   name: "carousel",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ui/carousel"),
  // },
  // {
  //   path: "/ui/dropdown",
  //   name: "dropdown",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ui/dropdown"),
  // },
  // {
  //   path: "/ui/grid",
  //   name: "grid",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ui/grid"),
  // },
  // {
  //   path: "/ui/images",
  //   name: "images",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ui/images"),
  // },
  // {
  //   path: "/ui/lightbox",
  //   name: "lightbox",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ui/lightbox"),
  // },
  // {
  //   path: "/ui/modals",
  //   name: "modals",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ui/modals"),
  // },
  // {
  //   path: "/ui/rangeslider",
  //   name: "rangeslider",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ui/rangeslider"),
  // },
  // {
  //   path: "/ui/progressbar",
  //   name: "progressbar",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ui/progressbar"),
  // },
  // {
  //   path: "/ui/placeholder",
  //   name: "placeholder",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ui/placeholder"),
  // },
  // {
  //   path: "/ui/sweet-alert",
  //   name: "sweet-alert",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ui/sweet-alert"),
  // },

  // {
  //   path: "/ui/tabs-accordions",
  //   name: "tabs-accordions",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ui/tabs-accordions"),
  // },
  // {
  //   path: "/ui/typography",
  //   name: "typography",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ui/typography"),
  // },

  // {
  //   path: "/ui/video",
  //   name: "video",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ui/video"),
  // },
  // {
  //   path: "/ui/general",
  //   name: "general",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ui/general"),
  // },
  // {
  //   path: "/ui/colors",
  //   name: "colors",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ui/colors"),
  // },
  // {
  //   path: "/ui/rating",
  //   name: "rating",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/ui/rating"),
  // },
  // {
  //   path: "/auth/login-1",
  //   name: "login-1",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/auth/login-1"),
  // },
  {
    path: "/auth/register-1",
    name: "register-1",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/auth/register-1"),
  },
  {
    path: "/auth/lock-screen",
    name: "lock-screen",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/auth/lock-screen"),
  },
  {
    path: "/auth/recoverpwd",
    name: "recoverpwd",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/auth/recoverpwd"),
  },
];
