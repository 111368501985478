import Swal from "sweetalert2";

export const POPUP = {
  popupClick(data, timer = 1000, icon = "success") {
    Swal.fire({
      position: "top-end",
      icon: icon,
      title: data,
      showConfirmButton: false,
      timer: timer,
    });
  },
  popupClickNot(data, timer = 1000, icon = "error") {
    Swal.fire({
      position: "top-end",
      icon: icon,
      title: data,
      showConfirmButton: false,
      timer: timer,
    });
  },
  timer() {
    let timerInterval;
    Swal.fire({
      position: "top-end",
      title: "Auto close alert!",
      html: "I will close in <b></b> milliseconds.",
      timer: 50000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          Swal.getContent().querySelector(
            "b"
          ).textContent = Swal.getTimerLeft();
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
    }).then((result) => {
      if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.timer
      ) {
        console.log("I was closed by the timer"); // eslint-disable-line
      }
    });
  },
};
