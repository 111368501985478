<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form>
      <div class="row">
        <div class="col-lg-12">
          <div>
            <router-link :to="'/category/lang/' + this.$route.params.id" class="btn btn-success mb-3">
              <i class="mdi mdi-arrow-bottom-left-thick me-1"></i> Back
            </router-link>
          </div>
          <div id="header-accordion" class="custom-accordion">
            <!-- Page Genel Ayarlar -->
            <div class="card">
              <div class="p-4 border-top">
                <div class="row">

                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">Seo Title <strong>*</strong></label>
                      <input v-model="formData.seoTitle" type="text" class="form-control" required />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">Seo Description <strong>*</strong></label>
                      <input v-model="formData.seoDesc" type="text" class="form-control" required />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label for="namePage">Title <strong>*</strong></label>
                      <input v-model="formData.title" type="text" class="form-control" required />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label for="namePage">Slug <strong>*</strong></label>
                      <input v-model="formData.slug" type="text" class="form-control" required />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label for="namePage">Description <strong>*</strong></label>
                      <ckeditor v-model="formData.description" :editor="editor"></ckeditor>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">Short Description <strong>*</strong></label>
                      <input v-model="formData.short_description" type="text" class="form-control" required />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">Starts Price<strong>*</strong></label>
                      <input v-model="formData.starts_price" type="text" class="form-control" required />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">package content <strong>*</strong></label>

                      <ckeditor v-model="formData.package_content" :editor="editor"></ckeditor>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="slugPage">Image<strong>*</strong></label>
                      <input type="file" class="form-control" @change="fileChange('img', $event)" required />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label for="namePage">Video Link<strong>*</strong></label>
                      <input v-model="formData.video_link" type="text" class="form-control" required />
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label for="seoKeyword">Language <strong>*</strong></label>
                      <select required v-model="formData.language_id" id="" class="form-control">
                        <option v-for="(entry, key) in langs" :key="key" v-bind:value="entry.id">{{ entry.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Page Genel Ayarlar -->
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-end ms-1">
          <button type="button" class="btn btn-danger" :disabled="createSend == 1 ? true : false">
            <i class="uil uil-times me-1"></i> Cancel
          </button>
          <button type="submit" @click="save()" class="btn btn-success ms-1" :disabled="createSend == 1 ? true : false">
            <i class="uil uil-file-alt me-1"></i> Save
          </button>
        </div>
        <!-- end col -->
      </div>
    </form>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { API } from "@/state/API";
import { POPUP } from "@/state/popup";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  page: {
    title: "Category Create",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    // vueDropzone: vue2Dropzone,
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      title: "Category Create",
      items: [
        {
          text: "Category",
        },
        {
          text: "Create",
          active: true,
        },
      ],
      createSend: 0,
      formData: {},
      value1: null,
      options: [],
      langs: [],

      editor: ClassicEditor,
    };
  },
  middleware: "authentication",
  mounted() {
    this.FindLp();
  },
  methods: {
    async FindLp() {
      await API.Get(API.Category.Lang.LangList + this.$route.params.id).then(
        (response) => {
          if (response.data.status == "success") {
            this.langs = response.data.order;
          } else {
            this.cats = [];
          }
        }
      );
    },
    convertToFormData() {
      const _formData = new FormData();

      Object.keys(this.formData).map((key) => {
        _formData.append(key, this.formData[key]);
      });

      return _formData;
    },
    async save() {
      this.createSend = 1;
      var data;
      const response = await API.Post(
        API.Category.Lang.Create + this.$route.params.id,
        this.convertToFormData()
      );
      console.log(response.data);
      if (response.data.status == "success") {
        this.createSend = 0;
        return this.$router.push("/category/lang/" + this.$route.params.id);
      } else {
        this.createSend = 0;
        data = "Error";
        POPUP.popupClickNot(data);
      }
    },

    fileChange(fieldName, $event) {
      if ($event.target.files && $event.target.files.length) {
        this.formData[fieldName] = $event.target.files[0];
      }
    },
  },
};
</script>

<style scoped>
strong {
  color: rgba(239, 4, 4, 0.39);
}

.border-col {
  box-shadow: 0px 0px 5px 0px #ddd;
  padding: 10px;
}
</style>
