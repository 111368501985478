<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form>
      <div class="row">
        <div class="col-lg-12">
          <div>
            <router-link :to="'/utm-ad'" class="btn btn-success mb-3">
              <i class="mdi mdi-arrow-bottom-left-thick me-1"></i> Back
            </router-link>
          </div>
          <div id="header-accordion" class="custom-accordion">
            <!-- Page Genel Ayarlar -->
            <div class="card">
              <div class="p-4 border-top">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">Code <strong>*</strong></label>
                      <input v-model="formData.code" type="text" class="form-control" required />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">utm_ad <strong>*</strong></label>
                      <input v-model="formData.name" type="text" class="form-control" required />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Page Genel Ayarlar -->
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-end ms-1">
          <button type="button" class="btn btn-danger" :disabled="createSend == 1 ? true : false">
            <i class="uil uil-times me-1"></i> Cancel
          </button>
          <button type="submit" @click="save()" class="btn btn-success ms-1" :disabled="createSend == 1 ? true : false">
            <i class="uil uil-file-alt me-1"></i> Save
          </button>
        </div>
        <!-- end col -->
      </div>
    </form>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { API } from "@/state/API";
import { POPUP } from "@/state/popup";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  page: {
    title: "Utm Ad Cases Update",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    // vueDropzone: vue2Dropzone,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Utm Ad Cases Update",
      items: [
        {
          text: "Utm Ad Cases Update",
        },
        {
          text: "Create",
          active: true,
        },
      ],
      createSend: 0,
      formData: {
        order: null,
        code: null,
        name: null,
      },
      value1: null,
      options: [],
      langs: [],
    };
  },
  middleware: "authentication",
  mounted() {
    this.FindLp();
  },
  methods: {
    async FindLp() {
      await API.Get(API.UtmAd.UpdateList + this.$route.params.id).then(
        (response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.formData = response.data.order;
          }
        }
      );
    },
    convertToFormData() {
      const _formData = new FormData();

      Object.keys(this.formData).map((key) => {
        _formData.append(key, this.formData[key]);
      });

      return _formData;
    },
    async save() {
      this.createSend = 1;
      var data;
      const response = await API.Post(
        API.UtmAd.Update + this.$route.params.id,
        this.convertToFormData()
      );
      console.log(response.data);
      if (response.data.status == "success") {
        this.createSend = 0;
        return this.$router.push("/utm-ad");
      } else {
        this.createSend = 0;
        data = "Error";
        POPUP.popupClickNot(data);
      }
    },

    fileChange(fieldName, $event) {
      if ($event.target.files && $event.target.files.length) {
        this.formData[fieldName] = $event.target.files[0];
      }
    },
  },
};
</script>

<style scoped>
strong {
  color: rgba(239, 4, 4, 0.39);
}

.border-col {
  box-shadow: 0px 0px 5px 0px #ddd;
  padding: 10px;
}
</style>
