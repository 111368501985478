<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form>
      <div class="row">
        <div class="col-lg-12">
          <div>
            <router-link :to="'/landing-page'" class="btn btn-success mb-3">
              <i class="mdi mdi-arrow-bottom-left-thick me-1"></i> Back
            </router-link>
          </div>
          <div id="header-accordion" class="custom-accordion">
            <!-- Page Genel Ayarlar -->
            <div class="card">
              <div class="p-4 border-top">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">Name <strong>*</strong></label>
                      <input
                        v-model="formData.namePage"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="slugPage">Slug <strong>*</strong></label>
                      <input
                        v-model="formData.slugPage"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="seoTitle">SEO Title <strong>*</strong></label>
                      <input
                        v-model="formData.seoTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="seoDescription"
                        >SEO Description <strong>*</strong></label
                      >
                      <input
                        v-model="formData.seoDescription"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="seoKeyword"
                        >Language <strong>*</strong></label
                      >
                      <select
                        required
                        v-model="formData.language"
                        id=""
                        class="form-control"
                      >
                        <option
                          v-for="(entry, key) in langs"
                          :key="key"
                          v-bind:value="entry.locale"
                          >{{ entry.name }}</option
                        >
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="seoKeyword">Type <strong>*</strong></label>
                      <select
                        required
                        v-model="formData.type"
                        id=""
                        class="form-control"
                      >
                        <option
                          v-for="(entry, key) in types"
                          :key="key"
                          v-bind:value="entry"
                          >{{ entry }}</option
                        >
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Slider Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-1
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="
                            avatar-title
                            rounded-circle
                            bg-soft-primary
                            text-primary
                          "
                        >
                          01
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Header Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-1"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label for="headerTitle">Header Main Title </label>
                      <input
                        v-model="formData.headerTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label for="headerTitle">Header Top Title </label>
                      <input
                        v-model="formData.headerTopTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="mb-3 mb-0 col-6">
                      <label for="headerDescription"
                        >Header Sub Description
                      </label>
                      <input
                        v-model="formData.headerSubTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label for="headerTitle">Header Button Download </label>
                      <input
                        v-model="formData.headerBtn1"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label for="headerTitle">Header Button Form </label>
                      <input
                        v-model="formData.headerBtn2"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="border-top col-6">
                      <label for="">Header Image </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('headerImg', $event)"
                        required
                      />
                    </div>
                    <div class="border-top col-6">
                      <label for="">Header Mobile Image </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('headerImgMobile', $event)"
                        required
                      />
                    </div>
                    <div class="col-12">
                      <label for="headerTitle"
                        >Background Image Alt Text
                      </label>
                      <input
                        id="headerImgSubText"
                        v-model="formData.headerImgSubText"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Form Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-2
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="
                            avatar-title
                            rounded-circle
                            bg-soft-primary
                            text-primary
                          "
                        >
                          02
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Form Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-2"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label for="headerTitle">Form Title </label>
                      <input
                        v-model="formData.formTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-4 mb-3">
                      <label for="headerTitle">Full Name </label>
                      <input
                        v-model="formData.formFullname"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-4 mb-3">
                      <label for="headerTitle">Phone </label>
                      <input
                        v-model="formData.formPhone"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-4 mb-3">
                      <label for="headerTitle">Email </label>
                      <input
                        v-model="formData.formEmail"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <label for="headerTitle">Button </label>
                      <input
                        v-model="formData.formButton"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <label for="Checkbox">Checkbox </label>
                      <input
                        v-model="formData.formCheckbox"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="border-top col-6">
                      <label for="">Form Image </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('formImg', $event)"
                        required
                      />
                    </div>
                    <div class="col-6">
                      <label for="headerTitle">Image Alt Text </label>
                      <input
                        id="headerImgSubText"
                        v-model="formData.formImgAltText"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Steps Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-3
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="
                            avatar-title
                            rounded-circle
                            bg-soft-primary
                            text-primary
                          "
                        >
                          03
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Steps Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-3"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="row" v-for="item in 3" :key="item">
                      <div class="col-2 mb-3">
                        <label>Icon {{ item }} </label>
                        <input
                          type="file"
                          class="form-control"
                          @change="fileChange('step' + item + 'Icon', $event)"
                          required
                        />
                      </div>
                      <div class="col-2 mb-3">
                        <label>Alt Text {{ item }} </label>
                        <input
                          v-model="formData['step' + item + 'IconAltText']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="col-4 mb-3">
                        <label>Title {{ item }} </label>
                        <input
                          v-model="formData['step' + item + 'Title']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>

                      <div class="col-4 mb-3">
                        <label>Description {{ item }} </label>
                        <input
                          v-model="formData['step' + item + 'Desc']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- methods Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-10
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="
                avatar-title
                rounded-circle
                bg-soft-primary
                text-primary
              "
                        >
                          04
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Methods Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-10"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-6 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.methodsTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Description</label>
                      <input
                        v-model="formData.methodsText"
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <div class="row" v-for="item in 6" :key="item">
                      <div class="col-6 mb-3">
                        <label>Title {{ item }} </label>
                        <input
                          v-model="formData['methods' + item + 'Title']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label>Description {{ item }} </label>
                        <input
                          v-model="formData['methods' + item + 'Desc']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label>Photo {{ item }} </label>
                        <input
                          type="file"
                          class="form-control"
                          @change="
                            fileChange('methods' + item + 'Photo', $event)
                          "
                          required
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label>Alt Text {{ item }} </label>
                        <input
                          v-model="formData['methods' + item + 'PhotoAltText']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Before After Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-5
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          05
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Before After Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-5"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.beforeAfterTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="row" v-for="item in 6" :key="item">
                      <div class="col-6 mb-3">
                        <label>Before Image {{ item }} </label>
                        <input
                          type="file"
                          class="form-control"
                          @change="
                            fileChange('before' + item + 'Photo', $event)
                          "
                          required
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label>After Photo {{ item }} </label>
                        <input
                          type="file"
                          class="form-control"
                          @change="fileChange('after' + item + 'Photo', $event)"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Why Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-6
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="
                            avatar-title
                            rounded-circle
                            bg-soft-primary
                            text-primary
                          "
                        >
                          06
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Why Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-6"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.whyTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="row" v-for="item in 6" :key="item">
                      <div class="col-2 mb-3">
                        <label>Icon {{ item }} </label>
                        <input
                          type="file"
                          class="form-control"
                          @change="fileChange('why' + item + 'Icon', $event)"
                          required
                        />
                      </div>
                      <div class="col-2 mb-3">
                        <label>Alt Text {{ item }} </label>
                        <input
                          v-model="formData['why' + item + 'IconAltText']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="col-4 mb-3">
                        <label>Title {{ item }} </label>
                        <input
                          v-model="formData['why' + item + 'Title']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>

                      <div class="col-4 mb-3">
                        <label>Description {{ item }} </label>
                        <input
                          v-model="formData['why' + item + 'Desc']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- About Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-07
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          07
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">About Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-07"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-6 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.aboutTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Description</label>
                      <input
                        v-model="formData.aboutDesc"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Background Image </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('aboutBgimage', $event)"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Background Image Mobile </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('aboutBgimageMobile', $event)"
                        required
                      />
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- About 2 Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-16
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          08
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">About 2 Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-16"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-6 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.about2Title"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Description</label>
                      <input
                        v-model="formData.about2Desc"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Background Image </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('about2Bgimage', $event)"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Background Image Mobile </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('about2BgimageMobile', $event)"
                        required
                      />
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>

            <!-- Doctor Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-25
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          09
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Doctor Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-25"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-6 mb-3">
                      <label>Full Name</label>
                      <input
                        v-model="formData.doctorFirstFullName"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>

                    <div class="col-6 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.doctorFirstTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Description</label>
                      <input
                        v-model="formData.doctorFirstDesc"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Photo </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('doctorFirstPhoto', $event)"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Photo Mobile </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('doctorFirstMobilePhoto', $event)"
                        required
                      />
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Pdf Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-26
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          10
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">PDF Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-26"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row"> 
                    <div class="col-12 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.pdfAreaTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Item 1</label>
                      <input
                        v-model="formData.pdfAreaItem1"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Item 2</label>
                      <input
                        v-model="formData.pdfAreaItem2"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    
                    <div class="col-6 mb-3">
                      <label>Item 3</label>
                      <input
                        v-model="formData.pdfAreaItem3"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Photo </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('pdAreaPhoto', $event)"
                        required
                      />
                    </div> 
                  </div>
                </div>
              </b-collapse>
            </div>

            <!-- Insurance Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-20
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          08
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Insurance Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-20"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-6 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.insuranceTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Image </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('insuranceImage', $event)"
                        required
                      />
                    </div>

                    <div class="row" v-for="item in 3" :key="item">
                      <div class="col-6 mb-3">
                        <label>Title {{ item }}</label>
                        <input
                          v-model="formData['insuranceTitle' + item]"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>

                      <div class="col-6 mb-3">
                        <label>Desc {{ item }} </label>
                        <input
                          v-model="formData['insuranceDesc' + item]"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Team Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-21
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="
                            avatar-title
                            rounded-circle
                            bg-soft-primary
                            text-primary
                          "
                        >
                          21
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Doctors Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-21"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label>Title {{ item }} </label>
                      <input
                        v-model="formData['teamTitle']"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="row" v-for="item in 5" :key="item">
                      <div class="col-2 mb-3">
                        <label>Image {{ item }} </label>
                        <input
                          type="file"
                          class="form-control"
                          @change="fileChange('team' + item + 'Doctor', $event)"
                          required
                        />
                      </div>
                      <div class="col-2 mb-3">
                        <label>Alt Text {{ item }} </label>
                        <input
                          v-model="formData['team' + item + 'DoctorAltText']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="col-4 mb-3">
                        <label>Title {{ item }} </label>
                        <input
                          v-model="formData['team' + item + 'Title']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>

                      <div class="col-4 mb-3">
                        <label>Description {{ item }} </label>
                        <input
                          v-model="formData['team' + item + 'Desc']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Two Video Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-08
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          08
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Triple Video Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-08"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.twoTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="row" v-for="item in 3" :key="item">
                      <div class="col-4 mb-3">
                        <label>Link {{ item }}</label>
                        <input
                          v-model="formData['twoVideoLink' + item]"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="col-4 mb-3">
                        <label>Cover Image {{ item }}</label>
                        <input
                          type="file"
                          class="form-control"
                          @change="fileChange('twoVideoCover' + item, $event)"
                          required
                        />
                      </div>

                      <div class="col-4 mb-3">
                        <label>Image Alt Text {{ item }} </label>
                        <input
                          v-model="
                            formData['twoVideoCover' + item + 'PhotoAltText']
                          "
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Procedures Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-17
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="
                avatar-title
                rounded-circle
                bg-soft-primary
                text-primary
              "
                        >
                          09
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Procedures Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-17"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.proceduresTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="row" v-for="item in 15" :key="item">
                      <div class="col-2 mb-3">
                        <label>Image {{ item }} </label>
                        <input
                          type="file"
                          class="form-control"
                          @change="
                            fileChange('procedures' + item + 'Img', $event)
                          "
                          required
                        />
                      </div>
                      <div class="col-2 mb-3">
                        <label>Alt Text {{ item }} </label>
                        <input
                          v-model="formData['procedures' + item + 'ImgAltText']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="col-4 mb-3">
                        <label>Title {{ item }} </label>
                        <input
                          v-model="formData['procedures' + item + 'Title']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>

                      <div class="col-4 mb-3">
                        <label>Description {{ item }} </label>
                        <input
                          v-model="formData['procedures' + item + 'Desc']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Amenities Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-7
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="
                avatar-title
                rounded-circle
                bg-soft-primary
                text-primary
              "
                        >
                          09
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Amenities Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-7"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.amenitiesTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="row" v-for="item in 8" :key="item">
                      <div class="col-2 mb-3">
                        <label>Icon {{ item }} </label>
                        <input
                          type="file"
                          class="form-control"
                          @change="
                            fileChange('amenities' + item + 'Icon', $event)
                          "
                          required
                        />
                      </div>
                      <div class="col-2 mb-3">
                        <label>Alt Text {{ item }} </label>
                        <input
                          v-model="formData['amenities' + item + 'IconAltText']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="col-4 mb-3">
                        <label>Title {{ item }} </label>
                        <input
                          v-model="formData['amenities' + item + 'Title']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>

                      <div class="col-4 mb-3">
                        <label>Description {{ item }} </label>
                        <input
                          v-model="formData['amenities' + item + 'Desc']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Banner Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-10
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          10
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Banner Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-10"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 ">
                      <label>Image </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('bannerImage', $event)"
                        required
                      />
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Gallery Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-18
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          10
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Gallery Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-18"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.galleryTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Three Video Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-11
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          11
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Triple Video Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-11"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.threeTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="row" v-for="item in 3" :key="item">
                      <div class="col-4 mb-3">
                        <label>Link {{ item }}</label>
                        <input
                          v-model="formData['threeVideoLink' + item]"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="col-4 mb-3">
                        <label>Cover Image {{ item }}</label>
                        <input
                          type="file"
                          class="form-control"
                          @change="fileChange('threeVideoCover' + item, $event)"
                          required
                        />
                      </div>
                      <div class="col-4 mb-3">
                        <label>Image Alt Text {{ item }} </label>
                        <input
                          v-model="
                            formData['threeVideoCover' + item + 'PhotoAltText']
                          "
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Consultation Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-12
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          12
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Consultation Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-12"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.consultationTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Description</label>
                      <input
                        v-model="formData.consultationDesc"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Button Text</label>
                      <input
                        v-model="formData.consultationBtnText"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- four Video Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-13
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          13
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Quad Video Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-13"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.fourTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="row" v-for="item in 4" :key="item">
                      <div class="col-4 mb-3">
                        <label>Link {{ item }}</label>
                        <input
                          v-model="formData['fourVideoLink' + item]"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="col-4 mb-3">
                        <label>Cover Image {{ item }}</label>
                        <input
                          type="file"
                          class="form-control"
                          @change="fileChange('fourVideoCover' + item, $event)"
                          required
                        />
                      </div>

                      <div class="col-4 mb-3">
                        <label>Image Alt Text {{ item }} </label>
                        <input
                          v-model="
                            formData['fourVideoCover' + item + 'PhotoAltText']
                          "
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- F.A.Q. Area -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-img-collapse"
                v-b-toggle.accordion-14
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          14
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">First F.A.Q. Section</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                id="accordion-14"
                accordion="my-accordion"
                data-parent="#addproduct-accordion"
              >
                <div class="p-4 row">
                  <div class="col-6 mb-4">
                    <label for="">Title </label>
                    <input
                      type="text"
                      class="form-control"
                      name="faqAreaTitle"
                      v-model="formData.faqAreaTitle"
                      required
                    />
                  </div>
                  <div class="col-6 mb-4">
                    <label for="">Description </label>
                    <input
                      type="text"
                      class="form-control"
                      name="faqAreaText"
                      v-model="formData.faqAreaText"
                      required
                    />
                  </div>
                  <!-- TextBox -->

                  <div
                    class="col-lg-6 border-col"
                    v-for="item in 6"
                    :key="item"
                  >
                    <div class="row">
                      <div class="col-12">
                        <div class="mb-3">
                          <label :for="'faqAreaTitle' + item"
                            >Question {{ item }}
                          </label>
                          <input
                            v-model="formData['faqAreaTitle' + item]"
                            type="text"
                            class="form-control"
                            :required="item < 5 ? true : false"
                          />
                        </div>
                      </div>

                      <div class="mb-3 mb-0 col-12">
                        <label :for="'faqAreaDescription' + item">
                          Answer {{ item }}
                        </label>
                        <textarea
                          class="form-control"
                          v-model="formData['faqAreaDescription' + item]"
                          rows="4"
                          :required="item < 5 ? true : false"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <!-- TextBox end -->
                </div>
              </b-collapse>
            </div>
            <!-- Second F.A.Q. Area -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-img-collapse"
                v-b-toggle.accordion-15
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          15
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Second F.A.Q. Section</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                id="accordion-15"
                accordion="my-accordion"
                data-parent="#addproduct-accordion"
              >
                <div class="p-4 row">
                  <div class="col-6 mb-4">
                    <label for="">Title </label>
                    <input
                      type="text"
                      class="form-control"
                      name="faqAreaTitle"
                      v-model="formData.secondFaqAreaTitle"
                      required
                    />
                  </div>
                  <div class="col-6 mb-4">
                    <label for="">Description </label>
                    <input
                      type="text"
                      class="form-control"
                      name="faqAreaText"
                      v-model="formData.secondFaqAreaText"
                      required
                    />
                  </div>
                  <!-- TextBox -->

                  <div
                    class="col-lg-6 border-col"
                    v-for="item in 6"
                    :key="item"
                  >
                    <div class="row">
                      <div class="col-12">
                        <div class="mb-3">
                          <label :for="'faqAreaTitle' + item"
                            >Question {{ item }}
                          </label>
                          <input
                            v-model="formData['secondFaqAreaTitle' + item]"
                            type="text"
                            class="form-control"
                            :required="item < 5 ? true : false"
                          />
                        </div>
                      </div>

                      <div class="mb-3 mb-0 col-12">
                        <label :for="'secondFaqAreaDescription' + item">
                          Answer {{ item }}
                        </label>
                        <textarea
                          class="form-control"
                          v-model="formData['secondFaqAreaDescription' + item]"
                          rows="4"
                          :required="item < 5 ? true : false"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <!-- TextBox end -->
                </div>
              </b-collapse>
            </div>
            <!-- Comment Area -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-img-collapse"
                v-b-toggle.accordion-22
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          16
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Comment Section</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                id="accordion-22"
                accordion="my-accordion"
                data-parent="#addproduct-accordion"
              >
                <div class="p-4 row">
                  <div class="col-12 mb-4">
                    <label for="">Title </label>
                    <input
                      type="text"
                      class="form-control"
                      name="commentAreaTitle"
                      v-model="formData.commentAreaTitle"
                      required
                    />
                  </div>
                  <!-- TextBox -->

                  <div
                    class="col-lg-6 border-col"
                    v-for="item in 20"
                    :key="item"
                  >
                    <div class="row">
                      <div class="col-6">
                        <label :for="'commentTitle' + item"
                          >Name {{ item }}
                        </label>
                        <input
                          v-model="formData['commentTitle' + item]"
                          type="text"
                          class="form-control"
                        />
                      </div>

                      <div class="mb-3 mb-0 col-6">
                        <label :for="'commentOperation' + item"
                          >Operation {{ item }}
                        </label>
                        <input
                          v-model="formData['commentOperation' + item]"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label>Profile Photo </label>
                        <input
                          type="file"
                          class="form-control"
                          @change="fileChange('commentImage' + item, $event)"
                        />
                      </div>
                      <div class="mb-3 mb-0 col-12">
                        <label :for="'commentDescription' + item">
                          Comment {{ item }} (max 165 Character)
                        </label>
                        <textarea
                          class="form-control"
                          v-model="formData['commentDescription' + item]"
                          rows="4"
                          maxlength="165"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <!-- TextBox end -->
                </div>
              </b-collapse>
            </div>
            <!-- Surgeries Area -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-img-collapse"
                v-b-toggle.accordion-23
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          17
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Surgeries Section</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                id="accordion-23"
                accordion="my-accordion"
                data-parent="#addproduct-accordion"
              >
                <div class="p-4 row">
                  <div class="col-12 mb-4">
                    <label for="">Title </label>
                    <input
                      type="text"
                      class="form-control"
                      name="surgeriesAreaTitle"
                      v-model="formData.surgeriesAreaTitle"
                      required
                    />
                  </div>
                  <div class="col-12 mb-4">
                    <label for="">Button Text</label>
                    <input
                      type="text"
                      class="form-control"
                      name="surgeriesAreaButton"
                      v-model="formData.surgeriesAreaButton"
                      required
                    />
                  </div>
                  <!-- TextBox -->

                  <div
                    class="col-lg-6 border-col"
                    v-for="item in 4"
                    :key="item"
                  >
                    <div class="row">
                      <div class="col-6">
                        <label :for="'surgeryCategoryTitle' + item"
                          >Category {{ item }}
                        </label>
                        <input
                          v-model="formData['surgeryCategoryTitle' + item]"
                          type="text"
                          class="form-control"
                        />
                      </div>

                      <div class="col-6 mb-3">
                        <label>Surgery Image </label>
                        <input
                          type="file"
                          class="form-control"
                          @change="fileChange('surgeryImage' + item, $event)"
                        />
                      </div>
                      <div class="mb-3 mb-0 col-3" v-for="li in 10" :key="li">
                        <label :for="'surgeryDescription' + item" >
                         List Item {{ li }}  
                        </label>
                        <textarea
                          class="form-control"
                          v-model="formData['surgeryDescription' + item + '_' + li]"
                          rows="2"
                          maxlength="50"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <!-- TextBox end -->
                </div>
              </b-collapse>
            </div>

            <!-- Json Area -->
            <div class="mb-3 mb-0 col-12">
              <label> Json </label>
              <textarea class="form-control" v-model="formData.json" rows="6">
              </textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-end ms-1">
          <button
            type="button"
            class="btn btn-danger"
            :disabled="createSend == 1 ? true : false"
          >
            <i class="uil uil-times me-1"></i> Cancel
          </button>
          <button
            type="submit"
            @click="save()"
            class="btn btn-success ms-1"
            :disabled="createSend == 1 ? true : false"
          >
            <i class="uil uil-file-alt me-1"></i> Save
          </button>
        </div>
        <!-- end col -->
      </div>
    </form>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { API } from "@/state/API2";
import { POPUP } from "@/state/popup";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  page: {
    title: "Landing Page Create",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    // vueDropzone: vue2Dropzone,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Landing Page Create",
      items: [
        {
          text: "Landing Page",
        },
        {
          text: "Create",
          active: true,
        },
      ],
      createSend: 0,
      formData: {},
      InputCreate: 0,
      SelectBoxCreate: 0,
      manyOption: 1,
      types: ["hair", "bbl", "lipo", "doctor-b", "plastic"],
      // dropzoneOptions: {
      //   url: "https://httpbin.org/post",
      //   thumbnailWidth: 2000,
      //   maxFilesize: 1,
      //   headers: {
      //     "My-Awesome-Header": "header value",
      //   },
      // },
      value1: null,
      options: [],
      langs: [],
    };
  },
  middleware: "authentication",
  mounted() {
    this.FindLp();
  },
  methods: {
    async FindLp() {
      await API.Get(API.Languages.Index).then((response) => {
        response.data.languages.forEach((element) => {
          this.langs.push(element);
        });
      });
    },
    convertToFormData() {
      const _formData = new FormData();

      Object.keys(this.formData).map((key) => {
        _formData.append(key, this.formData[key]);
      });

      return _formData;
    },
    async save() {
      this.createSend = 1;
      var data;

      const response = await API.Post(
        API.LandingPage.Create + "?type=" + this.formData.type,
        this.convertToFormData()
      );
      if (response.data.status == "slug") {
        data = "Slug has been used before";
        POPUP.popupClickNot(data);

        this.createSend = 0;
      } else if (response.data.status == "success") {
        this.createSend = 0;
        return this.$router.push("/landing-page");
      } else {
        this.createSend = 0;
        data = "Error";
        POPUP.popupClickNot(data);
      }
    },

    fileChange(fieldName, $event) {
      if ($event.target.files && $event.target.files.length) {
        this.formData[fieldName] = $event.target.files[0];
      }
    },
    optionChange(event) {
      if (!event) {
        this.manyOption = 0;
      } else {
        this.manyOption = event;
      }
    },
  },
};
</script>

<style scoped>
strong {
  color: rgba(239, 4, 4, 0.39);
}
.border-col {
  box-shadow: 0px 0px 5px 0px #ddd;
  padding: 10px;
}
</style>
