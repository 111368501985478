<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form>
      <div class="row">
        <div class="col-lg-12">
          <div>
            <router-link :to="'/landing-page'" class="btn btn-success mb-3">
              <i class="mdi mdi-arrow-bottom-left-thick me-1"></i> Back
            </router-link>
            <button
              type="button"
              class="btn btn-warning mb-3 ml-4"
              @click="FindLp()"
              :disabled="statusRefresh == 0 ? true : false"
              style="margin-left: 5px"
            >
              <i class="fas fa-undo-alt"></i>
              Refresh
            </button>
          </div>
          <div id="header-accordion" class="custom-accordion">
            <!-- Page Genel Ayarlar -->
            <div class="card">
              <div class="p-4 border-top">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">Name <strong>*</strong></label>
                      <input
                        v-model="formData.namePage"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="slugPage">Slug <strong>*</strong></label>
                      <input
                        v-model="formData.slugPage"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="seoTitle">SEO Title <strong>*</strong></label>
                      <input
                        v-model="formData.seoTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="seoDescription"
                        >SEO Description <strong>*</strong></label
                      >
                      <input
                        v-model="formData.seoDescription"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="seoKeyword"
                        >Language <strong>*</strong></label
                      >
                      <select
                        required
                        v-model="formData.language"
                        id=""
                        class="form-control"
                      >
                        <option
                          v-for="(entry, key) in langs"
                          :key="key"
                          v-bind:value="entry.locale"
                          >{{ entry.name }}</option
                        >
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Slider Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-1
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="
                            avatar-title
                            rounded-circle
                            bg-soft-primary
                            text-primary
                          "
                        >
                          01
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Header Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-1"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label for="headerTitle">Header Main Title </label>
                      <input
                        v-model="formData.headerTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label for="headerTitle">Header Top Title </label>
                      <input
                        v-model="formData.headerTopTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="mb-3 mb-0 col-6">
                      <label for="headerDescription"
                        >Header Sub Description
                      </label>
                      <input
                        v-model="formData.headerSubTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label for="headerTitle">Header Button Download </label>
                      <input
                        v-model="formData.headerBtn1"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label for="headerTitle">Header Button Form </label>
                      <input
                        v-model="formData.headerBtn2"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="border-top col-6">
                      <label for="">Header Image </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('headerImg', $event)"
                        required
                      />
                    </div>
                    <div class="border-top col-6">
                      <label for="">Header Mobile Image </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('headerImgMobile', $event)"
                        required
                      />
                    </div>
                    <div class="col-12">
                      <label for="headerTitle"
                        >Background Image Alt Text
                      </label>
                      <input
                        id="headerImgSubText"
                        v-model="formData.headerImgSubText"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Form Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-2
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="
                            avatar-title
                            rounded-circle
                            bg-soft-primary
                            text-primary
                          "
                        >
                          02
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Form Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-2"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label for="headerTitle">Form Title </label>
                      <input
                        v-model="formData.formTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-4 mb-3">
                      <label for="headerTitle">Full Name </label>
                      <input
                        v-model="formData.formFullname"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-4 mb-3">
                      <label for="headerTitle">Phone </label>
                      <input
                        v-model="formData.formPhone"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-4 mb-3">
                      <label for="headerTitle">Email </label>
                      <input
                        v-model="formData.formEmail"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <label for="headerTitle">Button </label>
                      <input
                        v-model="formData.formButton"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <label for="Checkbox">Checkbox </label>
                      <input
                        v-model="formData.formCheckbox"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="border-top col-6">
                      <label for="">Form Image </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('formImg', $event)"
                        required
                      />
                    </div>
                    <div class="col-6">
                      <label for="headerTitle">Image Alt Text </label>
                      <input
                        id="headerImgSubText"
                        v-model="formData.formImgAltText"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>

            <!-- Steps Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-3
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="
                            avatar-title
                            rounded-circle
                            bg-soft-primary
                            text-primary
                          "
                        >
                          03
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Steps Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-3"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="row" v-for="item in 3" :key="item">
                      <div class="col-2 mb-3">
                        <label>Icon {{ item }} </label>
                        <input
                          type="file"
                          class="form-control"
                          @change="fileChange('step' + item + 'Icon', $event)"
                          required
                        />
                      </div>
                      <div class="col-2 mb-3">
                        <label>Alt Text {{ item }} </label>
                        <input
                          v-model="formData['step' + item + 'IconAltText']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="col-4 mb-3">
                        <label>Title {{ item }} </label>
                        <input
                          v-model="formData['step' + item + 'Title']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>

                      <div class="col-4 mb-3">
                        <label>Description {{ item }} </label>
                        <input
                          v-model="formData['step' + item + 'Desc']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- methods Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-04
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="
                avatar-title
                rounded-circle
                bg-soft-primary
                text-primary
              "
                        >
                          04
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Methods Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-04"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-6 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.methodsTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Description</label>
                      <input
                        v-model="formData.methodsText"
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <div class="row" v-for="item in 6" :key="item">
                      <div class="col-6 mb-3">
                        <label>Title {{ item }} </label>
                        <input
                          v-model="formData['methods' + item + 'Title']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label>Description {{ item }} </label>
                        <input
                          v-model="formData['methods' + item + 'Desc']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label>Photo {{ item }} </label>
                        <input
                          type="file"
                          class="form-control"
                          @change="
                            fileChange('methods' + item + 'Photo', $event)
                          "
                          required
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label>Alt Text {{ item }} </label>
                        <input
                          v-model="formData['methods' + item + 'PhotoAltText']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Before After Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-5
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          05
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Before After Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-5"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.beforeAfterTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="row" v-for="item in 6" :key="item">
                      <div class="col-6 mb-3">
                        <label>Before Image {{ item }} </label>
                        <input
                          type="file"
                          class="form-control"
                          @change="
                            fileChange('before' + item + 'Photo', $event)
                          "
                          required
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label>After Photo {{ item }} </label>
                        <input
                          type="file"
                          class="form-control"
                          @change="fileChange('after' + item + 'Photo', $event)"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Why Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-6
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="
                            avatar-title
                            rounded-circle
                            bg-soft-primary
                            text-primary
                          "
                        >
                          06
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Why Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-6"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.whyTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="row" v-for="item in 6" :key="item">
                      <div class="col-2 mb-3">
                        <label>Icon {{ item }} </label>
                        <input
                          type="file"
                          class="form-control"
                          @change="fileChange('why' + item + 'Icon', $event)"
                          required
                        />
                      </div>
                      <div class="col-2 mb-3">
                        <label>Alt Text {{ item }} </label>
                        <input
                          v-model="formData['why' + item + 'IconAltText']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="col-4 mb-3">
                        <label>Title {{ item }} </label>
                        <input
                          v-model="formData['why' + item + 'Title']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>

                      <div class="col-4 mb-3">
                        <label>Description {{ item }} </label>
                        <input
                          v-model="formData['why' + item + 'Desc']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- About Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-07
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          07
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">About Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-07"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-6 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.aboutTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Description</label>
                      <input
                        v-model="formData.aboutDesc"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Background Image </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('aboutBgimage', $event)"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Background Image Mobile </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('aboutBgimageMobile', $event)"
                        required
                      />
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- About Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-16
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          08
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">About 2 Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-16"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-6 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.about2Title"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Description</label>
                      <input
                        v-model="formData.about2Desc"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Background Image </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('about2Bgimage', $event)"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Background Image Mobile </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('about2BgimageMobile', $event)"
                        required
                      />
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Doctor Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-25
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          09
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Doctor Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-25"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-6 mb-3">
                      <label>Full Name</label>
                      <input
                        v-model="formData.doctorFirstFullName"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>

                    <div class="col-6 mb-3">
                      <label>Doctor Title</label>
                      <input
                        v-model="formData.doctorFirsDoctorTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>

                    <div class="col-6 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.doctorFirstTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Description</label>
                      <input
                        v-model="formData.doctorFirstDesc"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Photo </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('doctorFirstPhoto', $event)"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Photo Mobile </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('doctorFirstMobilePhoto', $event)"
                        required
                      />
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>

            <!-- Pdf Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-26
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          10
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">PDF Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-26"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.pdfAreaTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Item 1</label>
                      <input
                        v-model="formData.pdfAreaItem1"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Item 2</label>
                      <input
                        v-model="formData.pdfAreaItem2"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>

                    <div class="col-6 mb-3">
                      <label>Item 3</label>
                      <input
                        v-model="formData.pdfAreaItem3"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Photo </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('pdAreaPhoto', $event)"
                        required
                      />
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Insurance Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-20
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          08
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Insurance Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-20"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-6 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.insuranceTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Image </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('insuranceImage', $event)"
                        required
                      />
                    </div>

                    <div class="row" v-for="item in 3" :key="item">
                      <div class="col-6 mb-3">
                        <label>Title {{ item }}</label>
                        <input
                          v-model="formData['insuranceTitle' + item]"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>

                      <div class="col-6 mb-3">
                        <label>Desc {{ item }} </label>
                        <input
                          v-model="formData['insuranceDesc' + item]"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>

            <!-- Team Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-21
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="
                            avatar-title
                            rounded-circle
                            bg-soft-primary
                            text-primary
                          "
                        >
                          21
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Doctor Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-21"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label>Title {{ item }} </label>
                      <input
                        v-model="formData['teamTitle']"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="row" v-for="item in 5" :key="item">
                      <div class="col-2 mb-3">
                        <label>Image {{ item }} </label>
                        <input
                          type="file"
                          class="form-control"
                          @change="fileChange('team' + item + 'Doctor', $event)"
                          required
                        />
                      </div>
                      <div class="col-2 mb-3">
                        <label>Alt Text {{ item }} </label>
                        <input
                          v-model="formData['team' + item + 'DoctorAltText']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="col-4 mb-3">
                        <label>Title {{ item }} </label>
                        <input
                          v-model="formData['team' + item + 'Title']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>

                      <div class="col-4 mb-3">
                        <label>Description {{ item }} </label>
                        <input
                          v-model="formData['team' + item + 'Desc']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Two Video Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-08
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          08
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Triple Video Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-08"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.twoTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="row" v-for="item in 3" :key="item">
                      <div class="col-4 mb-3">
                        <label>Link {{ item }}</label>
                        <input
                          v-model="formData['twoVideoLink' + item]"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="col-4 mb-3">
                        <label>Cover Image {{ item }}</label>
                        <input
                          type="file"
                          class="form-control"
                          @change="fileChange('twoVideoCover' + item, $event)"
                          required
                        />
                      </div>

                      <div class="col-4 mb-3">
                        <label>Image Alt Text {{ item }} </label>
                        <input
                          v-model="
                            formData['twoVideoCover' + item + 'PhotoAltText']
                          "
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Procedures Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-17
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="
                avatar-title
                rounded-circle
                bg-soft-primary
                text-primary
              "
                        >
                          09
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Procedures Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-17"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.proceduresTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="row" v-for="item in 15" :key="item">
                      <div class="col-2 mb-3">
                        <label>Image {{ item }} </label>
                        <input
                          type="file"
                          class="form-control"
                          @change="
                            fileChange('procedures' + item + 'Img', $event)
                          "
                          required
                        />
                      </div>
                      <div class="col-2 mb-3">
                        <label>Alt Text {{ item }} </label>
                        <input
                          v-model="formData['procedures' + item + 'ImgAltText']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="col-4 mb-3">
                        <label>Title {{ item }} </label>
                        <input
                          v-model="formData['procedures' + item + 'Title']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>

                      <div class="col-4 mb-3">
                        <label>Description {{ item }} </label>
                        <input
                          v-model="formData['procedures' + item + 'Desc']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Amenities Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-7
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="
                avatar-title
                rounded-circle
                bg-soft-primary
                text-primary
              "
                        >
                          09
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Amenities Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-7"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.amenitiesTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="row" v-for="item in 8" :key="item">
                      <div class="col-2 mb-3">
                        <label>Icon {{ item }} </label>
                        <input
                          type="file"
                          class="form-control"
                          @change="
                            fileChange('amenities' + item + 'Icon', $event)
                          "
                          required
                        />
                      </div>
                      <div class="col-2 mb-3">
                        <label>Alt Text {{ item }} </label>
                        <input
                          v-model="formData['amenities' + item + 'IconAltText']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="col-4 mb-3">
                        <label>Title {{ item }} </label>
                        <input
                          v-model="formData['amenities' + item + 'Title']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>

                      <div class="col-4 mb-3">
                        <label>Description {{ item }} </label>
                        <input
                          v-model="formData['amenities' + item + 'Desc']"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Banner Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-10
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          10
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Banner Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-10"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 ">
                      <label>Image </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('bannerImage', $event)"
                        required
                      />
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Gallery Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-18
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          10
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Gallery Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-18"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.galleryTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Three Video Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-11
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          11
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Triple Video Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-11"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.threeTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="row" v-for="item in 3" :key="item">
                      <div class="col-4 mb-3">
                        <label>Link {{ item }}</label>
                        <input
                          v-model="formData['threeVideoLink' + item]"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="col-4 mb-3">
                        <label>Cover Image {{ item }}</label>
                        <input
                          type="file"
                          class="form-control"
                          @change="fileChange('threeVideoCover' + item, $event)"
                          required
                        />
                      </div>
                      <div class="col-4 mb-3">
                        <label>Image Alt Text {{ item }} </label>
                        <input
                          v-model="
                            formData['threeVideoCover' + item + 'PhotoAltText']
                          "
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Consultation Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-12
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          12
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Consultation Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-12"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.consultationTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Description</label>
                      <input
                        v-model="formData.consultationDesc"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label>Button Text</label>
                      <input
                        v-model="formData.consultationBtnText"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- four Video Alanı -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-13
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          13
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Quad Video Area</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#header-accordion"
                id="accordion-13"
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <label>Title</label>
                      <input
                        v-model="formData.fourTitle"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    <div class="row" v-for="item in 4" :key="item">
                      <div class="col-4 mb-3">
                        <label>Link {{ item }}</label>
                        <input
                          v-model="formData['fourVideoLink' + item]"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="col-4 mb-3">
                        <label>Cover Image {{ item }}</label>
                        <input
                          type="file"
                          class="form-control"
                          @change="fileChange('fourVideoCover' + item, $event)"
                          required
                        />
                      </div>

                      <div class="col-4 mb-3">
                        <label>Image Alt Text {{ item }} </label>
                        <input
                          v-model="
                            formData['fourVideoCover' + item + 'PhotoAltText']
                          "
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- F.A.Q. Area -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-img-collapse"
                v-b-toggle.accordion-14
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          14
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">First F.A.Q. Section</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                id="accordion-14"
                accordion="my-accordion"
                data-parent="#addproduct-accordion"
              >
                <div class="p-4 row">
                  <div class="col-6 mb-4">
                    <label for="">Title </label>
                    <input
                      type="text"
                      class="form-control"
                      name="faqAreaTitle"
                      v-model="formData.faqAreaTitle"
                      required
                    />
                  </div>
                  <div class="col-6 mb-4">
                    <label for="">Description </label>
                    <input
                      type="text"
                      class="form-control"
                      name="faqAreaText"
                      v-model="formData.faqAreaText"
                      required
                    />
                  </div>
                  <!-- TextBox -->

                  <div
                    class="col-lg-6 border-col"
                    v-for="item in 6"
                    :key="item"
                  >
                    <div class="row">
                      <div class="col-12">
                        <div class="mb-3">
                          <label :for="'faqAreaTitle' + item"
                            >Question {{ item }}
                          </label>
                          <input
                            v-model="formData['faqAreaTitle' + item]"
                            type="text"
                            class="form-control"
                            :required="item < 5 ? true : false"
                          />
                        </div>
                      </div>

                      <div class="mb-3 mb-0 col-12">
                        <label :for="'faqAreaDescription' + item">
                          Answer {{ item }}
                        </label>
                        <textarea
                          class="form-control"
                          v-model="formData['faqAreaDescription' + item]"
                          rows="4"
                          :required="item < 5 ? true : false"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <!-- TextBox end -->
                </div>
              </b-collapse>
            </div>
            <!-- Second F.A.Q. Area -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-img-collapse"
                v-b-toggle.accordion-15
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          15
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Second F.A.Q. Section</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                id="accordion-15"
                accordion="my-accordion"
                data-parent="#addproduct-accordion"
              >
                <div class="p-4 row">
                  <div class="col-6 mb-4">
                    <label for="">Title </label>
                    <input
                      type="text"
                      class="form-control"
                      name="faqAreaTitle"
                      v-model="formData.secondFaqAreaTitle"
                      required
                    />
                  </div>
                  <div class="col-6 mb-4">
                    <label for="">Description </label>
                    <input
                      type="text"
                      class="form-control"
                      name="faqAreaText"
                      v-model="formData.secondFaqAreaText"
                      required
                    />
                  </div>
                  <!-- TextBox -->

                  <div
                    class="col-lg-6 border-col"
                    v-for="item in 6"
                    :key="item"
                  >
                    <div class="row">
                      <div class="col-12">
                        <div class="mb-3">
                          <label :for="'faqAreaTitle' + item"
                            >Question {{ item }}
                          </label>
                          <input
                            v-model="formData['secondFaqAreaTitle' + item]"
                            type="text"
                            class="form-control"
                            :required="item < 5 ? true : false"
                          />
                        </div>
                      </div>

                      <div class="mb-3 mb-0 col-12">
                        <label :for="'secondFaqAreaDescription' + item">
                          Answer {{ item }}
                        </label>
                        <textarea
                          class="form-control"
                          v-model="formData['secondFaqAreaDescription' + item]"
                          rows="4"
                          :required="item < 5 ? true : false"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <!-- TextBox end -->
                </div>
              </b-collapse>
            </div>
            <!-- Comment Area -->
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-img-collapse"
                v-b-toggle.accordion-22
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          16
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Comment Section</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                id="accordion-22"
                accordion="my-accordion"
                data-parent="#addproduct-accordion"
              >
                <div class="p-4 row">
                  <div class="col-12 mb-4">
                    <label for="">Title </label>
                    <input
                      type="text"
                      class="form-control"
                      name="commentAreaTitle"
                      v-model="formData.commentAreaTitle"
                      required
                    />
                  </div>
                  <!-- TextBox -->

                  <div
                    class="col-lg-6 border-col"
                    v-for="item in 20"
                    :key="item"
                  >
                    <div class="row">
                      <div class="col-6">
                        <label :for="'commentTitle' + item"
                          >Name {{ item }}
                        </label>
                        <input
                          v-model="formData['commentTitle' + item]"
                          type="text"
                          class="form-control"
                        />
                      </div>

                      <div class="mb-3 mb-0 col-6">
                        <label :for="'commentOperation' + item"
                          >Operation {{ item }}
                        </label>
                        <input
                          v-model="formData['commentOperation' + item]"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label>Profile Photo </label>
                        <input
                          type="file"
                          class="form-control"
                          @change="fileChange('commentImage' + item, $event)"
                        />
                      </div>
                      <div class="mb-3 mb-0 col-12">
                        <label :for="'commentDescription' + item">
                          Comment {{ item }} (max 165 Character)
                        </label>
                        <textarea
                          class="form-control"
                          v-model="formData['commentDescription' + item]"
                          rows="4"
                          maxlength="165"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <!-- TextBox end -->
                </div>
              </b-collapse>
            </div>

                        <!-- Surgeries Area -->
                        <div class="card">
                          <a
                            href="javascript: void(0);"
                            class="text-dark collapsed"
                            data-toggle="collapse"
                            aria-expanded="false"
                            aria-controls="addproduct-img-collapse"
                            v-b-toggle.accordion-23
                          >
                            <div class="p-4">
                              <div class="media align-items-center">
                                <div class="me-3">
                                  <div class="avatar-xs">
                                    <div
                                      class="avatar-title rounded-circle bg-soft-primary text-primary"
                                    >
                                      17
                                    </div>
                                  </div>
                                </div>
                                <div class="media-body overflow-hidden">
                                  <h5 class="font-size-16 mb-1">Surgeries Section</h5>
                                  <p class="text-muted text-truncate mb-0">
                                    Fill all information below
                                  </p>
                                </div>
                                <i
                                  class="mdi mdi-chevron-up accor-down-icon font-size-24"
                                ></i>
                              </div>
                            </div>
                          </a>
            
                          <b-collapse
                            id="accordion-23"
                            accordion="my-accordion"
                            data-parent="#addproduct-accordion"
                          >
                            <div class="p-4 row">
                              <div class="col-12 mb-4">
                                <label for="">Title </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="surgeriesAreaTitle"
                                  v-model="formData.surgeriesAreaTitle"
                                  required
                                />
                              </div>
                              <div class="col-12 mb-4">
                                <label for="">Button Text</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="surgeriesAreaButton"
                                  v-model="formData.surgeriesAreaButton"
                                  required
                                />
                              </div>
                              <!-- TextBox -->
            
                              <div
                                class="col-lg-6 border-col"
                                v-for="item in 4"
                                :key="item"
                              >
                                <div class="row">
                                  <div class="col-6">
                                    <label :for="'surgeryCategoryTitle' + item"
                                      >Category {{ item }}
                                    </label>
                                    <input
                                      v-model="formData['surgeryCategoryTitle' + item]"
                                      type="text"
                                      class="form-control"
                                    />
                                  </div>
            
                                  <div class="col-6 mb-3">
                                    <label>Surgery Image </label>
                                    <input
                                      type="file"
                                      class="form-control"
                                      @change="fileChange('surgeryImage' + item, $event)" 
                                    />
                                  </div>
                                  <div class="mb-3 mb-0 col-3" v-for="li in 10" :key="li">
                                    <label :for="'surgeryDescription' + item" >
                                     List Item {{ li }}  
                                    </label>
                                    <textarea
                                      class="form-control"
                                      v-model="formData['surgeryDescription' + item + '_' + li]"
                                      rows="2"
                                      maxlength="50"
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                              <!-- TextBox end -->
                            </div>
                          </b-collapse>
                        </div>

            <div class="mb-3 mb-0 col-12">
              <label> Json </label>
              <textarea class="form-control" v-model="formData.json" rows="6">
              </textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-end ms-1">
          <button
            type="button"
            class="btn btn-danger"
            :disabled="updateSend == 1 ? true : false"
          >
            <i class="uil uil-times me-1"></i> Cancel
          </button>
          <button
            type="submit"
            @click="save()"
            class="btn btn-success ms-1"
            :disabled="updateSend == 1 ? true : false"
          >
            <i class="uil uil-file-alt me-1"></i> Save
          </button>
        </div>
        <!-- end col -->
      </div>
    </form>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// import vue2Dropzone from "vue2-dropzone";
// import Multiselect from "vue-multiselect";

import { API } from "@/state/API2";

import { POPUP } from "@/state/popup";

import "vue2-dropzone/dist/vue2Dropzone.min.css";
// import { formatDate } from "@fullcalendar/common";
// import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  page: {
    title: "Landing Page Update",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Landing Page Update",
      items: [
        {
          text: "Landing Page",
        },
        {
          text: "Update",
          active: true,
        },
      ],
      statusRefresh: 1,
      orderData: [],
      formData: {},
      imgUrl: "http://estetikinternational.com",
      formFields: [
        "namePage",
        "slugPage",
        "seoTitle",
        "seoDescription",
        "json",
        "headerTitle",
        "headerTopTitle",
        "headerSubTitle",
        "headerBtn1",
        "headerBtn2",
        "headerImg",
        "headerImgMobile",
        "headerImgSubText",
        "formTitle",
        "formFullname",
        "formPhone",
        "formEmail",
        "formButton",
        "formCheckbox",
        "formButton",
        "formImg",
        "formImgAltText",
        "step1Icon",
        "step1IconAltText",
        "step1Title",
        "step1Desc",
        "step2Icon",
        "step2IconAltText",
        "step2Title",
        "step2Desc",
        "step3Icon",
        "step3IconAltText",
        "step3Title",
        "step3Desc",
        "methodsTitle",
        "methodsText",
        "methods1Title",
        "methods1Desc",
        "methods1Photo",
        "methods1PhotoAltText",
        "methods2Title",
        "methods2Desc",
        "methods2Photo",
        "methods2PhotoAltText",
        "methods3Title",
        "methods3Desc",
        "methods3Photo",
        "methods3PhotoAltText",
        "methods4Title",
        "methods4Desc",
        "methods4Photo",
        "methods4PhotoAltText",
        "methods5Title",
        "methods5Desc",
        "methods5Photo",
        "methods5PhotoAltText",
        "methods6Title",
        "methods6Desc",
        "methods6Photo",
        "methods6PhotoAltText",
        "beforeAfterTitle",
        "before1Photo",
        "after1Photo",
        "before2Photo",
        "after2Photo",
        "before3Photo",
        "after3Photo",
        "before4Photo",
        "after4Photo",
        "before5Photo",
        "after5Photo",
        "before6Photo",
        "after6Photo",
        "whyTitle",
        "why1Icon",
        "why1IconAltText",
        "why1Title",
        "why1Desc",
        "why2Icon",
        "why2IconAltText",
        "why2Title",
        "why2Desc",
        "why3Icon",
        "why3IconAltText",
        "why3Title",
        "why3Desc",
        "why4Icon",
        "why4IconAltText",
        "why4Title",
        "why4Desc",
        "why5Icon",
        "why5IconAltText",
        "why5Title",
        "why5Desc",
        "why6Icon",
        "why6IconAltText",
        "why6Title",
        "why6Desc",
        "aboutTitle",
        "aboutDesc",
        "aboutBgimage",
        "about2Title",
        "about2Desc",
        "about2Bgimage",
        "twoTitle",
        "twoVideoLink1",
        "twoVideoCover1",
        "twoVideoCover1PhotoAltText",
        "twoVideoLink2",
        "twoVideoCover2",
        "twoVideoCover2PhotoAltText",
        "twoVideoLink3",
        "twoVideoCover3",
        "twoVideoCover3PhotoAltText",
        "amenitiesTitle",
        "amenities1Icon",
        "amenities1IconAltText",
        "amenities1Title",
        "amenities1Desc",
        "amenities2Icon",
        "amenities2IconAltText",
        "amenities2Title",
        "amenities2Desc",
        "amenities3Icon",
        "amenities3IconAltText",
        "amenities3Title",
        "amenities3Desc",
        "amenities4Icon",
        "amenities4IconAltText",
        "amenities4Title",
        "amenities4Desc",
        "amenities5Icon",
        "amenities5IconAltText",
        "amenities5Title",
        "amenities5Desc",
        "amenities6Icon",
        "amenities6IconAltText",
        "amenities6Title",
        "amenities6Desc",
        "amenities7Icon",
        "amenities7IconAltText",
        "amenities7Title",
        "amenities7Desc",
        "amenities8Icon",
        "amenities8IconAltText",
        "amenities8Title",
        "amenities8Desc",
        "bannerImage",
        "galleryTitle",
        "threeTitle",
        "threeVideoLink1",
        "threeVideoCover1",
        "threeVideoCover1PhotoAltText",
        "threeVideoLink2",
        "threeVideoCover2",
        "threeVideoCover2PhotoAltText",
        "threeVideoLink3",
        "threeVideoCover3",
        "threeVideoCover3PhotoAltText",
        "consultationTitle",
        "consultationDesc",
        "consultationBtnText",
        "fourTitle",
        "fourVideoLink1",
        "fourVideoCover1",
        "fourVideoCover1PhotoAltText",
        "fourVideoLink2",
        "fourVideoCover2",
        "fourVideoCover2PhotoAltText",
        "fourVideoLink3",
        "fourVideoCover3",
        "fourVideoCover3PhotoAltText",
        "fourVideoLink4",
        "fourVideoCover4",
        "fourVideoCover4PhotoAltText",
        "faqAreaTitle",
        "faqAreaText",
        "faqAreaTitle1",
        "faqAreaDescription1",
        "faqAreaTitle2",
        "faqAreaDescription2",
        "faqAreaTitle3",
        "faqAreaDescription3",
        "faqAreaTitle4",
        "faqAreaDescription4",
        "faqAreaTitle5",
        "faqAreaDescription5",
        "faqAreaTitle6",
        "faqAreaDescription6",
        "secondFaqAreaTitle",
        "secondFaqAreaText",
        "secondFaqAreaTitle1",
        "secondFaqAreaDescription1",
        "secondFaqAreaTitle2",
        "secondFaqAreaDescription2",
        "secondFaqAreaTitle3",
        "secondFaqAreaDescription3",
        "secondFaqAreaTitle4",
        "secondFaqAreaDescription4",
        "secondFaqAreaTitle5",
        "secondFaqAreaDescription5",
        "secondFaqAreaTitle6",
        "secondFaqAreaDescription6",
        "procedures1Img",
        "procedures1ImgAltText",
        "procedures1Title",
        "procedures1Desc",
        "procedures2Img",
        "procedures2ImgAltText",
        "procedures2Title",
        "procedures2Desc",
        "procedures3Img",
        "procedures3ImgAltText",
        "procedures3Title",
        "procedures3Desc",
        "procedures4Img",
        "procedures4ImgAltText",
        "procedures4Title",
        "procedures4Desc",
        "procedures5Img",
        "procedures5ImgAltText",
        "procedures5Title",
        "procedures5Desc",
        "procedures6Img",
        "procedures6ImgAltText",
        "procedures6Title",
        "procedures6Desc",
        "insuranceTitle",
        "insuranceImage",
        "insuranceTitle1",
        "insuranceDesc1",
        "insuranceTitle2",
        "insuranceDesc2",
        "insuranceTitle3",
        "insuranceDesc3",
        "teamTitle",
        "team1Title",
        "team1Desc",
        "team1DoctorAltText",
        "team2Title",
        "team2Desc",
        "team2DoctorAltText",
        "team3Title",
        "team3Desc",
        "team3DoctorAltText",
        "team4Title",
        "team4Desc",
        "team4DoctorAltText",
        "team5Title",
        "team5Desc",
        "team5DoctorAltText",
        "commentTitle",
        "commentTitle1",
        "commentOperation1",
        "commentImage1",
        "commentDescription1",
        "commentTitle2",
        "commentOperation2",
        "commentImage2",
        "commentDescription2",
        "commentTitle3",
        "commentOperation3",
        "commentImage3",
        "commentDescription3",
        "commentTitle4",
        "commentOperation4",
        "commentImage4",
        "commentDescription4",
        "commentTitle5",
        "commentOperation5",
        "commentImage5",
        "commentDescription5",
        "commentTitle6",
        "commentOperation6",
        "commentImage6",
        "commentDescription6",
        "commentTitle7",
        "commentOperation7",
        "commentImage7",
        "commentDescription7",
        "commentTitle8",
        "commentOperation8",
        "commentImage8",
        "commentDescription8",
        "commentTitle9",
        "commentOperation9",
        "commentImage9",
        "commentDescription9",
        "commentTitle10",
        "commentOperation10",
        "commentImage10",
        "commentDescription10",
        "commentTitle11",
        "commentOperation11",
        "commentImage11",
        "commentDescription11",
        "commentTitle12",
        "commentOperation12",
        "commentImage12",
        "commentDescription12",
        "commentTitle13",
        "commentOperation13",
        "commentImage13",
        "commentDescription13",
        "commentTitle14",
        "commentOperation14",
        "commentImage14",
        "commentDescription14",
        "commentTitle15",
        "commentOperation15",
        "commentImage15",
        "commentDescription15",
        "commentTitle16",
        "commentOperation16",
        "commentImage16",
        "commentDescription16",
        "commentTitle17",
        "commentOperation17",
        "commentImage17",
        "commentDescription17",
        "commentTitle18",
        "commentOperation18",
        "commentImage18",
        "commentDescription18",
        "commentTitle19",
        "commentOperation19",
        "commentImage19",
        "commentDescription19",
        "commentTitle20",
        "commentOperation20",
        "commentImage20",
        "commentDescription20",
        "doctorFirstFullName",
        "doctorFirsDoctorTitle",
        "doctorFirstTitle",
        "doctorFirstPhoto",
        "doctorFirstMobilePhoto",
        "doctorFirstDesc",
        "pdfAreaTitle",
        "pdfAreaItem1",
        "pdfAreaItem2",
        "pdfAreaItem3",
        "pdfAreaPhoto",
        "surgeriesAreaTitle",
        "surgeriesAreaButton",
        "surgeryCategoryTitle1",
        "surgeryCategoryTitle2",
        "surgeryCategoryTitle3",
        "surgeryCategoryTitle4",
        "surgeryImage1",
        "surgeryImage2",
        "surgeryImage3",
        "surgeryImage4",
        "surgeryDescription1_1",
        "surgeryDescription1_2",
        "surgeryDescription1_3",
        "surgeryDescription1_4",
        "surgeryDescription1_5",
        "surgeryDescription1_6",
        "surgeryDescription1_7",
        "surgeryDescription1_8",
        "surgeryDescription1_9",
        "surgeryDescription1_10",
        "surgeryDescription2_1",
        "surgeryDescription2_2",
        "surgeryDescription2_3",
        "surgeryDescription2_4",
        "surgeryDescription2_5",
        "surgeryDescription2_6",
        "surgeryDescription2_7",
        "surgeryDescription2_8",
        "surgeryDescription2_9",
        "surgeryDescription2_10",
        "surgeryDescription3_1",
        "surgeryDescription3_2",
        "surgeryDescription3_3",
        "surgeryDescription3_4",
        "surgeryDescription3_5",
        "surgeryDescription3_6",
        "surgeryDescription3_7",
        "surgeryDescription3_8",
        "surgeryDescription3_9",
        "surgeryDescription3_10",
        "surgeryDescription4_1",
        "surgeryDescription4_2",
        "surgeryDescription4_3",
        "surgeryDescription4_4",
        "surgeryDescription4_5",
        "surgeryDescription4_6",
        "surgeryDescription4_7",
        "surgeryDescription4_8",
        "surgeryDescription4_9",
        "surgeryDescription4_10",
      ],
      InputCreate: 0,
      SelectBoxCreate: 0,
      manyOption: 1,
      updateSend: 0,
      editor: ClassicEditor,
      editorData:
        "<h3>Hello World!</h3><h5><b>This is an simple editable area.</b></h5>",
      // dropzoneOptions: {
      //   url: "https://httpbin.org/post",
      //   thumbnailWidth: 2000,
      //   maxFilesize: 1,
      //   headers: {
      //     "My-Awesome-Header": "header value",
      //   },
      // },
      value1: null,
      options: [],
      langs: [],
    };
  },
  middleware: "authentication",
  mounted() {
    this.FindLp();
  },
  methods: {
    convertToFormData() {
      const _formData = new FormData();

      Object.keys(this.formData).map((key) => {
        _formData.append(key, this.formData[key]);
      });

      return _formData;
    },
    async save() {
      this.updateSend = 1;
      console.log(this.convertToFormData());
      const response = await API.Post(
        API.LandingPage.Update + this.$route.params.id,
        this.convertToFormData()
      );
      var data;

      if (response.data.status == "slug") {
        data = "Slug has been used before";
        POPUP.popupClickNot(data);
        this.updateSend = 0;
      } else if (response.data.status == "success") {
        this.updateSend = 0;
        return this.$router.push("/landing-page");
      }
    },
    async FindLp() {
      var data;
      this.statusRefresh = 0;

      await API.Get(API.Languages.Index).then((response) => {
        response.data.languages.forEach((element) => {
          this.langs.push(element);
        });
      });
      await API.Get(API.LandingPage.UpdateList + this.$route.params.id).then(
        (response) => {
          if (response.data.status == "success") {
            this.formData.namePage = response.data.order.name;
            this.formData.slugPage = response.data.order.slug;
            this.formData.seoTitle = response.data.order.seo_title;
            this.formData.seoDescription = response.data.order.seo_description;
            this.formData.seoKeyword = response.data.order.seo_keyword;

            this.formFields.splice(this.formData.namePage, 1);
            this.formFields.splice(this.formData.slugPage, 1);
            this.formFields.splice(this.formData.seoTitle, 1);
            this.formFields.splice(this.formData.seoDescription, 1);
            this.formFields.splice(this.formData.seoKeyword, 1);

            this.formFields.map((value) => {
              this.formData[value] = response.data.fields[value];
            });

            data = "Your work has been refresh";
            POPUP.popupClick(data);

            this.statusRefresh = 1;
          } else {
            data = "Your work could not be renewed";
            POPUP.popupClickNot(data);

            this.statusRefresh = 1;
          }
        }
      );
    },
    CreateInput() {
      if (this.InputCreate + this.SelectBoxCreate < 4) {
        this.InputCreate++;
      }
    },
    CreateSelectBox() {
      if (this.InputCreate + this.SelectBoxCreate < 4) {
        this.SelectBoxCreate++;
      }
    },
    SelectBoxDelete() {
      this.SelectBoxCreate--;
      if (this.SelectBoxCreate == 0) {
        this.manyOption = 1;
      }
    },

    fileChange(fieldName, $event) {
      if ($event.target.files && $event.target.files.length) {
        this.formData[fieldName] = $event.target.files[0];
      }
    },
  },
};
</script>
<style scoped>
strong {
  color: rgba(239, 4, 4, 0.39);
}
.border-col {
  box-shadow: 0px 0px 5px 0px #ddd;
  padding: 10px;
}
img {
  margin: 5px;
}
</style>
