import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

// export const state = { token: localStorage.getItem("token") };

const giris = new Vuex.Store({
  state: {
    token: !!localStorage.getItem("token"),
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    clearToken(state) {
      state.token = "";
    },
  },
  actions: {
    login({ commit }, authData) {
      let authLink = "https://estetikinternational.com/api/auth/login";

      return axios
        .post(authLink, {
          email: authData.email,
          password: authData.password,
        })
        .then((response) => {
          if (response.data.status == "success") {
            commit("setToken", response.data.id);
            localStorage.setItem("token", response.data.id);
          }
        });
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.token !== "";
    },
  },
});

export default giris;
