<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form>
      <div class="row">
        <div class="col-lg-12">
          <div>
            <router-link :to="'/landing-page'" class="btn btn-success mb-3">
              <i class="mdi mdi-arrow-bottom-left-thick me-1"></i> Back
            </router-link>
          </div>
          <div id="header-accordion" class="custom-accordion">
            <!-- Page Genel Ayarlar -->
            <div class="card">
              <div class="p-4 border-top">
                <div class="row">

                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">Dashboard Name <strong>*</strong></label>
                      <input v-model="formData.name" type="text" class="form-control" required />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">Language Name <strong>*</strong></label>
                      <input v-model="formData.native_name" type="text" class="form-control" required />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="slugPage">Language Short Name <strong>*</strong></label>
                      <input v-model="formData.locale" type="text" class="form-control" required />
                    </div>
                  </div>


                  <div class="border-top col-6">
                    <label for="">Language Image </label>
                    <input type="file" class="form-control" @change="fileChange('langImg', $event)" required />
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <json-editor :initial-json="initialJson"></json-editor>
            </div>
            <!-- End Page Genel Ayarlar -->
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-end ms-1">
          <button type="button" class="btn btn-danger" :disabled="createSend == 1 ? true : false">
            <i class="uil uil-times me-1"></i> Cancel
          </button>
          <button type="submit" @click="save()" class="btn btn-success ms-1" :disabled="createSend == 1 ? true : false">
            <i class="uil uil-file-alt me-1"></i> Save
          </button>
        </div>
        <!-- end col -->
      </div>
    </form>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { API } from "@/state/API";
import { POPUP } from "@/state/popup";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

import JSONEditor from "jsoneditor";
import "../../../assets/css/jsoneditor.css";

export default {
  page: {
    title: "Landing Page Create",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    // vueDropzone: vue2Dropzone,
    Layout,
    PageHeader,
  },
  data() {
    return {
      initialJson: {

        HomePage: {
          wpButton: "Chat via WhatsApp",
          wpMessage: "-",
          wpNumber: "447488890475",
          formButton: "Free Consultation",
          navbar: {
            PatientStories: "Patient Stories",
            OurDoctors: "Our Doctors",
            OurClinics: "Our Clinics",
            AboutUs: "About Us",
            Partner: "Partner with us",
            wpMessage:
              "We helped 523,233 patients achieve their dream look. Let us help you through your transformative journey.",
          },
          orderArea: {
            title: "Your Journey With Us!",
            area1:
              "Choose the procedure you’re interested in, and submit your request through the form or WhatsApp number.",
            area2:
              "Our medical consultants, with the advice of our doctors and after reviewing your medical information, will tailor the best treatment plan for you with the best offer.",
            area3:
              "We will organize your medical trip from A to Z for a full VIP experience, and will assist you until you return home safely.",
          },
          PatientStories: "Patient Stories",
          Treatments: "Treatments",
          OurDoctors: "Our Doctors",
          BeforeAfter: "Before-After",
          MoreInfo: "More Information",
          LearnMore: "Learn More",
          GetAQuote: "Get a Quote >",
          About: "About",
          FAQ: "FAQ",
          pages: "Pages",
          TreatmentsVideo: "Treatments Video",
          CardInfo: "Important information!",
          TopOn: "Top 10 requests",
          WatchNow: "Watch Now",
          DoctorConsultation: "Paid Consultation With Doctor",
          OurClinics: "Our Clinics",
          IstanbulClinics: "Istanbul Quasar Clinic",
          IstanbulClinicsAbout: "Estetik International Quasar Clinic is one of the most prestigious clinics in Istanbul. With a broad range of services, from plastic surgery to medical aesthetics, hair transplants, and dental aesthetics, Dr. Bülent Cihantimur and his team offer patients and guests the most advanced and effective aesthetic treatments.",
          BursaClinics: "Bursa Clinic",
          BursaClinicsAbout: "Estetik International Bursa is an aesthetic and beauty center that offers a broad range of services, including plastic surgery, medical aesthetics, and hair transplants. Offering an incredible experience to not only locals but also international patients.",
          Componie: "B Group Companies",
          PackageContents: "Package Contents",
          bulletin:
            "Beauty begins with good health! <span>EstetikCep</span> is always here for you… Don’t miss out our special offers!",
          Newsletter: "Newsletter",
          footer:
            "For the last 25 years, we have helped many people achieve the best version of themselves. And we will continue to help many more in the upcoming years.",
          treatmentWarning: "Every human being is different. The surgery process and procedures we offer may change depending on your case."
        },
        AboutUsPage: {
          title: "About Us",
          Treatment: "Treatment Information",
          TreatmentDesc:
            "Estetik International, established in 1999. Founded as a superior aesthetics and beauty centre by world renowned doctor Bülent Cihantimur. With six service clinics in Istanbul as well as, three other locations in the Byomed Medical Centre in, Bursa. Op.Dr. Bülent Cihantimur and his team offer our patients the most advanced and effective cosmetic treatments, helping them achieve their desired results, passionately for the past 20 years. Estetik International is one of the best, respected and recognised health group, in Turkey and awarded multiple certificates of quality, in the field of health. With Clinic’s nationwide, we offer the broadest range of treatments, as well as unique treatments and techniques developed by our founder Op. Dr. Bülent Cihantimur.",
          WhyChooseUs: "Why Choose Us",
          WhyChooseUsDesc:
            "*Estetik International was designed and built from the ground up in 1999, for the sole purpose of providing a world- class center for plastic and cosmetic surgery. *Estetik International gained global reputation for its, unique treatments and techniques developed by our founder Op. Dr. Bülent Cihantimur. *With over 20 years of experience, our talented medical experts are amongst the best in their, respected fields. *Our priority and mission is you. We strongly believe that good communication between you and your surgeon is essential, when planning your treatments. *We offer, honest advice and most suitable treatments for you. Take a moment to review just few of the many reasons, why our practice should be your first choice for plastic surgery and non-surgical aesthetic procedures.",
          OurMission: "Our Mission",
          OurMissionDesc:
            "Estetik International stands for quality and perfection- In order to achieve that, we make sure we put safety, expertise and patient satisfaction at the core of what we do. Medical expertise- our physicians and nurses are amongst the very best, in their respective fields. Outstanding care- it is our duty to safeguard our patients and ensure, that their experience is a positive one.",
          OurVision: "Our Vision",
          OurVisionDesc:
            "To be the most reliable, reputable, innovative centre of aesthetics in the world. Committed to being the industry leader, in all our activities. Patient satisfaction- we want our patients, to be happy with their treatments. We aim to produce natural looking results to enhance our patients natural beauty and more than anything else, we want to meet and exceed our patients and guests expectations. Exceptional guest service- we thrive to provide our patients and guests, with exceptional service and experience",
        },
        PartnerPage: {
          title: "Partner with us",
          desc:
            "Fill out the form to become our business partner, and let's win together!",
          firstname: "First Name",
          lastname: "Last Name",
          phone: "Phone",
          email: "Email",
          message: "Message",
          countr: "Country",
          send: "Send"
        },
        DoctorPage: {
          years: "Years of Experience",
          procedure: "Performed Procedures",
          message: "A message for you",
          about: "About",
          cerfiticates: "Cerfiticates and Awards",
          treatments: "Treatments",
          techniques: "Techniques",
          societies: "Professional Societies"
        },
        FormArea: {
          title: "Fill out the form, and let us take care of you!",
          name: "Full Name",
          email: "Email",
          phone: "Phone",
          message: "Message",
          send: "Send Request",
          country: "Country",
          kvkk: "I consent to the processing of my personal data for health services in compliance with the Personal Data Protection Law.",
          kvkkLink: "#"
        }
      },
      title: "Language Create",
      items: [
        {
          text: "Language",
        },
        {
          text: "Create",
          active: true,
        },
      ],
      createSend: 0,
      formData: {},
      InputCreate: 0,
      SelectBoxCreate: 0,
      manyOption: 1,
      types: ["hair", "bbl", "lipo", "doctor-b", "plastic"],
      // dropzoneOptions: {
      //   url: "https://httpbin.org/post",
      //   thumbnailWidth: 2000,
      //   maxFilesize: 1,
      //   headers: {
      //     "My-Awesome-Header": "header value",
      //   },
      // },
      value1: null,
      options: [],
      langs: [],
    };
  },
  middleware: "authentication",
  mounted() {
    this.FindLp();
    const container = this.$el;
    const options = {};
    this.editor = new JSONEditor(container, options);
    this.editor.set(this.initialJson);
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
    }
  },
  methods: {
    async FindLp() {
      await API.Get(API.Languages.Index).then((response) => {
        response.data.languages.forEach((element) => {
          this.langs.push(element);
        });
      });
    },
    convertToFormData() {
      const _formData = new FormData();

      Object.keys(this.formData).map((key) => {
        _formData.append(key, this.formData[key]);
      });

      return _formData;
    },
    async save() {
      const jsonData = this.editor.get();
      console.log(jsonData);

      this.createSend = 1;
      var data;
      const response = await API.Post(
        API.LandingPage.Create + "?type=" + this.formData.type,
        this.convertToFormData()
      );
      if (response.data.status == "slug") {
        data = "Slug has been used before";
        POPUP.popupClickNot(data);
        this.createSend = 0;
      } else if (response.data.status == "success") {
        this.createSend = 0;
        return this.$router.push("/landing-page");
      } else {
        this.createSend = 0;
        data = "Error";
        POPUP.popupClickNot(data);
      }
    },

    fileChange(fieldName, $event) {
      if ($event.target.files && $event.target.files.length) {
        this.formData[fieldName] = $event.target.files[0];
      }
    },
    optionChange(event) {
      if (!event) {
        this.manyOption = 0;
      } else {
        this.manyOption = event;
      }
    },
  },
};
</script>

<style scoped>
strong {
  color: rgba(239, 4, 4, 0.39);
}

.border-col {
  box-shadow: 0px 0px 5px 0px #ddd;
  padding: 10px;
}
</style>
